<template>
  <div
    v-if="showBillingTerms"
    :data-sid="`product-billing-terms-${section.sid}`"
    class="position-relative text-container"
    :class="`--${section.sid}-margin`"
  >
    <highliter
      v-model="section.name"
      class="highlight-element"
      section-type="element"
      :hide-right="true"
      @move-to="to => $emit('move-to', to)"
      @add-subject="$emit('add-subject')"
    >
      <p
        :class="`--${section.sid}-billing-terms-text`"
        class="product-billing-terms m-0"
        style="overflow-wrap: anywhere;"
      >
        {{ product.term }}
      </p>
    </highliter>
  </div>
</template>

<script>
import SectionMixin from '@/components/mixins/SectionMixin'
import Highliter from '@/components/builder/utils/Highliter.vue'
import {mapState} from 'vuex'
export default {
  name: 'ElBillingTerms',
  mixins: [SectionMixin],
  components: {Highliter},
  computed: {
    ...mapState('products', {
      products: state => state.selected
    }),
    showBillingTerms () {
      return this.products[0].term
    },
    product () {
      return {
        term: this.products[0].term
      }
    }
  }
}
</script>

<style lang="scss">
.product-billing-terms {
  font-size: 12px;
  line-height: 17px;
  font-weight: 400;
  color: #9B9B9B;
  text-align: center;
}
</style>