import _ from 'lodash'

const state = () => ({
  loading: false,
  products: [],
  options: [],
  selected: [],
  limit: 500,
  search: ''
})

const actions = {
  async getProduct ({state}, id) {
    state.loading = true
    await axios.get(`api/user/payment-systems/products/${id}`)
      .then(({data}) => {
        state.loading = false
        return data.data
      })
  },
  async getProductList ({state, rootState, rootGetters}) {
    state.loading = true

    const { data: communityProducts } = await axios.get(`api/user/payment-systems/products?project_id=${rootState.projects.project.id}&per_page=${state.limit}&q=${state.search}`)
    if (rootGetters['auth/userRole'].includes('superadministrator') || rootState['auth/user'].id === 4) {
      this.loading = true
      const {data: masterProducts} = await axios.get(`api/admin/crm/subscriptions/plans?per_page=${state.limit}&${state.search}`)
      state.products = [
        ..._.filter(masterProducts.data[0].data, item => item.is_active === true),
        ...communityProducts.data.data
      ]
    } else {
      state.products = [
        ...communityProducts.data.data
      ]
    }
    state.loading = false
  },
  differenceOptions ({state}) {
    state.options = _.filter(state.products, item => !_.includes(state.selected, item))
  }
}

const mutations = {
  SET_SELECTED (state, payload) {
    state.selected = payload
  },
  PUSH_SELECTED (state, payload) {
    state.selected.push(payload)
  },
  SPLICE_SELECTED (state, payload) {
    state.selected.splice(payload, 1)
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}