<template>
  <div class="flex flex-col gap-[64px] font-[ProximaNova]">
    <div class="flex flex-col gap-[32px]">
      <h2 class="text-[22px] leading-[26px] font-[600]">
        Billing Dashboard
      </h2>

      <AccountOverview
        :interval="interval"
        :user-subscription="userSubscription"
      />

      <div
        v-if="!isSpecialPlan"
        class="relative w-full h-fit grid grid-cols-1 xl:grid-cols-2 gap-x-[32px] gap-y-[16px]"
      >
        <ModifyPlan
          v-if="!isAnnualPlan"
          :title="promo.title"
          :description="promo.description"
          :cta="promo.cta"
          state="upgrade"
          :business-plus="isBusinessPlusPlan"
        >
          <template #before>
            <div>
              <div class="w-[48px] h-[48px] flex items-center justify-center rounded-full bg-[#E9F0FD] text-[16px] text-[#4F83E3]">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17.5 16.6668H14.6667C11.8664 16.6668 10.4663 16.6668 9.39671 16.1219C8.4559 15.6425 7.691 14.8776 7.21163 13.9368C6.66667 12.8672 6.66667 11.4671 6.66667 8.66683V3.3335M6.66667 3.3335L10.8333 7.50016M6.66667 3.3335L2.5 7.50016"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
          </template>
        </ModifyPlan>
        <ModifyPlan />
      </div>
    </div>

    <div class="flex flex-col gap-[32px]">
      <h2 class="text-[22px] leading-[26px] font-[600]">
        Payment Method
      </h2>

      <div
        v-if="methods.length === 0"
        class="flex flex-col items-center justify-center gap-y-[8px]"
      >
        <svg
          width="137"
          height="130"
          viewBox="0 0 137 130"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          class="my-[24px]"
        >
          <g clip-path="url(#clip0_3067_22197)">
            <path
              d="M3.21725 91.6847H1.02678C0.754462 91.6847 0.49329 91.5765 0.300731 91.384C0.108172 91.1914 0 90.9303 0 90.6579C0 90.3856 0.108172 90.1245 0.300731 89.9319C0.49329 89.7393 0.754462 89.6312 1.02678 89.6312H3.21725C3.48957 89.6312 3.75074 89.7393 3.9433 89.9319C4.13586 90.1245 4.24403 90.3856 4.24403 90.6579C4.24403 90.9303 4.13586 91.1914 3.9433 91.384C3.75074 91.5765 3.48957 91.6847 3.21725 91.6847Z"
              fill="#4F83E3"
            />
            <path
              d="M11.7053 91.6847H9.24101C8.9687 91.6847 8.70752 91.5765 8.51496 91.384C8.32241 91.1914 8.21423 90.9303 8.21423 90.6579C8.21423 90.3856 8.32241 90.1245 8.51496 89.9319C8.70752 89.7393 8.9687 89.6312 9.24101 89.6312H11.7053C11.9776 89.6312 12.2388 89.7393 12.4313 89.9319C12.6239 90.1245 12.7321 90.3856 12.7321 90.6579C12.7321 90.9303 12.6239 91.1914 12.4313 91.384C12.2388 91.5765 11.9776 91.6847 11.7053 91.6847Z"
              fill="#4F83E3"
            />
            <path
              d="M6.29766 97.0925C6.02534 97.0925 5.76416 96.9843 5.57161 96.7917C5.37905 96.5992 5.27087 96.338 5.27087 96.0657V93.8752C5.27087 93.6029 5.37905 93.3417 5.57161 93.1492C5.76416 92.9566 6.02534 92.8484 6.29766 92.8484C6.56997 92.8484 6.83115 92.9566 7.02371 93.1492C7.21626 93.3417 7.32444 93.6029 7.32444 93.8752V96.0657C7.32444 96.338 7.21626 96.5992 7.02371 96.7917C6.83115 96.9843 6.56997 97.0925 6.29766 97.0925Z"
              fill="#4F83E3"
            />
            <path
              d="M6.29766 88.8782C6.02534 88.8782 5.76416 88.77 5.57161 88.5774C5.37905 88.3849 5.27087 88.1237 5.27087 87.8514V85.3871C5.27087 85.1148 5.37905 84.8536 5.57161 84.6611C5.76416 84.4685 6.02534 84.3604 6.29766 84.3604C6.56997 84.3604 6.83115 84.4685 7.02371 84.6611C7.21626 84.8536 7.32444 85.1148 7.32444 85.3871V87.8514C7.32444 88.1237 7.21626 88.3849 7.02371 88.5774C6.83115 88.77 6.56997 88.8782 6.29766 88.8782Z"
              fill="#4F83E3"
            />
            <path
              d="M122.872 118.792H120.681C120.409 118.792 120.148 118.684 119.955 118.491C119.762 118.298 119.654 118.037 119.654 117.765C119.654 117.493 119.762 117.232 119.955 117.039C120.148 116.846 120.409 116.738 120.681 116.738H122.872C123.144 116.738 123.405 116.846 123.598 117.039C123.79 117.232 123.898 117.493 123.898 117.765C123.898 118.037 123.79 118.298 123.598 118.491C123.405 118.684 123.144 118.792 122.872 118.792Z"
              fill="#4F83E3"
            />
            <path
              d="M131.36 118.792H128.895C128.623 118.792 128.362 118.684 128.169 118.491C127.977 118.298 127.869 118.037 127.869 117.765C127.869 117.493 127.977 117.232 128.169 117.039C128.362 116.846 128.623 116.738 128.895 116.738H131.36C131.632 116.738 131.893 116.846 132.086 117.039C132.278 117.232 132.386 117.493 132.386 117.765C132.386 118.037 132.278 118.298 132.086 118.491C131.893 118.684 131.632 118.792 131.36 118.792Z"
              fill="#4F83E3"
            />
            <path
              d="M125.952 124.199C125.68 124.199 125.418 124.091 125.226 123.899C125.033 123.706 124.925 123.445 124.925 123.173V120.982C124.925 120.71 125.033 120.449 125.226 120.256C125.418 120.064 125.68 119.955 125.952 119.955C126.224 119.955 126.485 120.064 126.678 120.256C126.871 120.449 126.979 120.71 126.979 120.982V123.173C126.979 123.445 126.871 123.706 126.678 123.899C126.485 124.091 126.224 124.199 125.952 124.199Z"
              fill="#4F83E3"
            />
            <path
              d="M125.952 115.985C125.68 115.985 125.418 115.877 125.226 115.684C125.033 115.492 124.925 115.231 124.925 114.958V112.494C124.925 112.222 125.033 111.961 125.226 111.768C125.418 111.576 125.68 111.467 125.952 111.467C126.224 111.467 126.485 111.576 126.678 111.768C126.871 111.961 126.979 112.222 126.979 112.494V114.958C126.979 115.231 126.871 115.492 126.678 115.684C126.485 115.877 126.224 115.985 125.952 115.985Z"
              fill="#4F83E3"
            />
            <path
              d="M126.02 18.3042H123.83C123.558 18.3042 123.297 18.196 123.104 18.0034C122.911 17.8109 122.803 17.5497 122.803 17.2774C122.803 17.0051 122.911 16.7439 123.104 16.5513C123.297 16.3588 123.558 16.2506 123.83 16.2506H126.02C126.293 16.2506 126.554 16.3588 126.747 16.5513C126.939 16.7439 127.047 17.0051 127.047 17.2774C127.047 17.5497 126.939 17.8109 126.747 18.0034C126.554 18.196 126.293 18.3042 126.02 18.3042Z"
              fill="#4F83E3"
            />
            <path
              d="M134.508 18.3042H132.044C131.772 18.3042 131.511 18.196 131.318 18.0034C131.126 17.8109 131.017 17.5497 131.017 17.2774C131.017 17.0051 131.126 16.7439 131.318 16.5513C131.511 16.3588 131.772 16.2506 132.044 16.2506H134.508C134.781 16.2506 135.042 16.3588 135.234 16.5513C135.427 16.7439 135.535 17.0051 135.535 17.2774C135.535 17.5497 135.427 17.8109 135.234 18.0034C135.042 18.196 134.781 18.3042 134.508 18.3042Z"
              fill="#4F83E3"
            />
            <path
              d="M129.101 24.7118C128.829 24.7118 128.567 24.6036 128.375 24.4111C128.182 24.2185 128.074 23.9573 128.074 23.685V21.4946C128.074 21.2222 128.182 20.9611 128.375 20.7685C128.567 20.5759 128.829 20.4678 129.101 20.4678C129.373 20.4678 129.634 20.5759 129.827 20.7685C130.019 20.9611 130.128 21.2222 130.128 21.4946V23.685C130.128 23.9573 130.019 24.2185 129.827 24.4111C129.634 24.6036 129.373 24.7118 129.101 24.7118Z"
              fill="#4F83E3"
            />
            <path
              d="M129.101 15.4976C128.828 15.4976 128.567 15.3894 128.375 15.1968C128.182 15.0043 128.074 14.7431 128.074 14.4708V12.0065C128.074 11.7342 128.182 11.473 128.375 11.2805C128.567 11.0879 128.828 10.9797 129.101 10.9797C129.373 10.9797 129.634 11.0879 129.827 11.2805C130.019 11.473 130.128 11.7342 130.128 12.0065V14.4708C130.128 14.7431 130.019 15.0043 129.827 15.1968C129.634 15.3894 129.373 15.4976 129.101 15.4976Z"
              fill="#4F83E3"
            />
            <path
              d="M132.044 46.8664C131.386 46.8664 130.742 46.6712 130.195 46.3055C129.648 45.9398 129.221 45.42 128.969 44.8119C128.717 44.2038 128.651 43.5346 128.78 42.889C128.908 42.2434 129.225 41.6504 129.691 41.1849C130.156 40.7195 130.749 40.4025 131.395 40.2741C132.04 40.1457 132.71 40.2116 133.318 40.4635C133.926 40.7154 134.446 41.142 134.811 41.6893C135.177 42.2366 135.372 42.88 135.372 43.5383C135.371 44.4206 135.02 45.2665 134.396 45.8904C133.772 46.5143 132.926 46.8653 132.044 46.8664ZM132.044 42.2637C131.792 42.2637 131.546 42.3385 131.336 42.4785C131.126 42.6186 130.963 42.8176 130.867 43.0505C130.77 43.2834 130.745 43.5397 130.794 43.7869C130.843 44.0342 130.965 44.2613 131.143 44.4395C131.321 44.6178 131.548 44.7392 131.795 44.7884C132.043 44.8376 132.299 44.8123 132.532 44.7158C132.765 44.6194 132.964 44.456 133.104 44.2464C133.244 44.0368 133.319 43.7904 133.319 43.5383C133.318 43.2005 133.183 42.8767 132.945 42.6378C132.706 42.3989 132.382 42.2644 132.044 42.2637Z"
              fill="#4F83E3"
            />
            <path
              d="M109.662 14.2654C109.445 14.2655 109.233 14.1964 109.058 14.0683C107.082 12.6311 105.025 11.3092 102.897 10.109C102.775 10.0455 102.667 9.95807 102.579 9.85184C102.491 9.74562 102.426 9.62281 102.386 9.49072C102.347 9.35864 102.335 9.21999 102.35 9.08304C102.365 8.94608 102.408 8.81364 102.476 8.69357C102.543 8.57351 102.635 8.4683 102.744 8.3842C102.853 8.30011 102.978 8.23886 103.111 8.2041C103.245 8.16934 103.384 8.16178 103.52 8.18187C103.656 8.20196 103.787 8.24929 103.905 8.32105C106.101 9.55924 108.224 10.9237 110.263 12.4076C110.438 12.5351 110.569 12.715 110.636 12.9214C110.703 13.1279 110.703 13.3503 110.636 13.5566C110.569 13.763 110.438 13.9428 110.262 14.0702C110.086 14.1975 109.875 14.2659 109.658 14.2654H109.662Z"
              fill="#4F83E3"
            />
            <path
              d="M53.0943 4.92582C52.8489 4.92541 52.6118 4.83714 52.4259 4.67699C52.2399 4.51684 52.1175 4.2954 52.0808 4.05278C52.044 3.81017 52.0954 3.56241 52.2256 3.35439C52.3557 3.14638 52.5561 2.99186 52.7904 2.91881C63.9912 -0.546525 75.9183 -0.933815 87.3203 1.79757C87.4546 1.82588 87.5819 1.88083 87.6946 1.95916C87.8072 2.0375 87.9031 2.13763 87.9764 2.25363C88.0498 2.36964 88.0991 2.49917 88.1215 2.63457C88.1439 2.76996 88.1389 2.90848 88.1069 3.04193C88.0749 3.17538 88.0164 3.30104 87.935 3.41151C87.8535 3.52198 87.7508 3.61501 87.6328 3.68509C87.5148 3.75517 87.3839 3.80087 87.248 3.81952C87.112 3.83816 86.9737 3.82936 86.8412 3.79363C75.7984 1.14956 64.2477 1.52454 53.3996 4.87928C53.3008 4.9101 53.1978 4.92579 53.0943 4.92582Z"
              fill="#4F83E3"
            />
            <path
              d="M28.2449 19.4774C28.0378 19.4791 27.835 19.4181 27.6632 19.3025C27.4914 19.1868 27.3585 19.0219 27.2821 18.8294C27.2057 18.6369 27.1893 18.4258 27.2351 18.2239C27.2808 18.0219 27.3865 17.8384 27.5384 17.6976C28.5543 16.7393 29.6139 15.8015 30.6872 14.9103C30.7907 14.8217 30.9107 14.7546 31.0403 14.7129C31.1698 14.6711 31.3065 14.6556 31.4421 14.6672C31.5778 14.6787 31.7098 14.7171 31.8305 14.7802C31.9512 14.8432 32.0581 14.9296 32.145 15.0344C32.232 15.1391 32.2973 15.2602 32.337 15.3904C32.3768 15.5206 32.3903 15.6574 32.3767 15.7929C32.3631 15.9283 32.3226 16.0598 32.2577 16.1795C32.1929 16.2992 32.1049 16.4048 31.9988 16.4901C30.9583 17.354 29.9315 18.263 28.9472 19.1913C28.7585 19.3732 28.507 19.4757 28.2449 19.4774Z"
              fill="#4F83E3"
            />
            <path
              d="M8.71955 74.0556C8.46977 74.0552 8.2287 73.9638 8.04146 73.7985C7.85422 73.6332 7.73366 73.4053 7.70236 73.1575C7.01328 67.6906 7.01328 62.1589 7.70236 56.692C8.74979 48.3312 11.4315 40.2581 15.5949 32.9323C15.6554 32.8047 15.7417 32.6909 15.8483 32.5982C15.9548 32.5054 16.0793 32.4357 16.2141 32.3932C16.3488 32.3508 16.4908 32.3366 16.6313 32.3516C16.7718 32.3666 16.9077 32.4104 17.0304 32.4803C17.1532 32.5501 17.2602 32.6446 17.3448 32.7577C17.4294 32.8709 17.4897 33.0002 17.522 33.1378C17.5543 33.2753 17.5579 33.418 17.5325 33.557C17.5072 33.6959 17.4534 33.8282 17.3746 33.9454C13.3436 41.0367 10.747 48.8517 9.73266 56.9453C9.06548 62.2413 9.06548 67.6 9.73266 72.896C9.74973 73.0297 9.74027 73.1654 9.70485 73.2954C9.66943 73.4255 9.60872 73.5472 9.5262 73.6538C9.44368 73.7603 9.34098 73.8496 9.22394 73.9164C9.10691 73.9832 8.97784 74.0263 8.84413 74.0433C8.80288 74.0498 8.76128 74.0539 8.71955 74.0556Z"
              fill="#4F83E3"
            />
            <path
              d="M40.1814 121.288C40.0017 121.288 39.8251 121.24 39.6694 121.151C37.1688 119.705 34.7667 118.095 32.4792 116.332C32.2635 116.165 32.1227 115.92 32.0878 115.65C32.0529 115.38 32.1267 115.107 32.293 114.891C32.4593 114.676 32.7045 114.535 32.9746 114.5C33.2447 114.465 33.5175 114.539 33.7332 114.705C35.9483 116.413 38.2744 117.972 40.6962 119.372C40.8924 119.485 41.0457 119.66 41.1324 119.869C41.2191 120.078 41.2343 120.31 41.1756 120.528C41.1169 120.747 40.9875 120.94 40.8077 121.078C40.628 121.215 40.4078 121.289 40.1814 121.289V121.288Z"
              fill="#4F83E3"
            />
            <path
              d="M72.0911 129.817C66.3293 129.82 60.5926 129.058 55.0315 127.551C54.7755 127.474 54.5597 127.3 54.4301 127.066C54.3004 126.832 54.2671 126.557 54.3372 126.299C54.4073 126.041 54.5752 125.82 54.8053 125.684C55.0355 125.548 55.3096 125.507 55.5695 125.57C65.3546 128.213 75.6309 128.471 85.5365 126.323C85.6698 126.29 85.8085 126.283 85.9442 126.305C86.08 126.326 86.2101 126.374 86.3268 126.447C86.4435 126.52 86.5445 126.615 86.6237 126.727C86.703 126.839 86.7589 126.966 86.7881 127.101C86.8173 127.235 86.8193 127.374 86.7939 127.509C86.7685 127.644 86.7163 127.772 86.6403 127.887C86.5643 128.001 86.4661 128.1 86.3515 128.175C86.2369 128.251 86.1083 128.303 85.9732 128.328C81.4122 129.319 76.7583 129.818 72.0911 129.817Z"
              fill="#4F83E3"
            />
            <path
              d="M103.405 121.628C103.179 121.628 102.96 121.552 102.781 121.414C102.602 121.276 102.474 121.082 102.417 120.864C102.359 120.645 102.376 120.414 102.463 120.205C102.551 119.997 102.705 119.823 102.901 119.712C104.436 118.847 105.954 117.905 107.411 116.913C107.522 116.837 107.648 116.783 107.78 116.755C107.913 116.727 108.049 116.725 108.182 116.75C108.315 116.775 108.442 116.826 108.555 116.9C108.668 116.974 108.766 117.07 108.842 117.181C108.918 117.293 108.971 117.419 108.999 117.552C109.026 117.684 109.027 117.821 109.001 117.954C108.976 118.086 108.924 118.213 108.85 118.326C108.775 118.439 108.679 118.535 108.566 118.611C107.061 119.635 105.494 120.607 103.912 121.501C103.757 121.586 103.582 121.63 103.405 121.628Z"
              fill="#4F83E3"
            />
            <path
              d="M119.487 108.766C119.288 108.767 119.094 108.709 118.927 108.601C118.76 108.493 118.628 108.339 118.547 108.157C118.467 107.975 118.441 107.774 118.472 107.577C118.504 107.381 118.592 107.198 118.726 107.051C125.738 99.2957 130.706 89.9153 133.182 79.7576C133.214 79.6266 133.271 79.5031 133.35 79.3941C133.43 79.2852 133.53 79.1929 133.645 79.1227C133.76 79.0525 133.888 79.0056 134.021 78.9847C134.154 78.9639 134.29 78.9695 134.422 79.0012C134.553 79.033 134.676 79.0902 134.785 79.1697C134.894 79.2492 134.986 79.3493 135.056 79.4644C135.127 79.5795 135.174 79.7074 135.194 79.8406C135.215 79.9738 135.21 80.1099 135.178 80.2409C132.623 90.7306 127.494 100.418 120.255 108.428C120.159 108.536 120.04 108.621 119.908 108.68C119.775 108.738 119.632 108.767 119.487 108.766Z"
              fill="#4F83E3"
            />
            <path
              d="M135.973 65.9358C135.701 65.9358 135.44 65.8276 135.247 65.635C135.055 65.4425 134.947 65.1813 134.947 64.909C134.947 63.017 134.862 61.1085 134.693 59.2371C134.556 57.7024 134.361 56.1581 134.113 54.6453C134.073 54.3787 134.14 54.1072 134.299 53.8895C134.458 53.6718 134.696 53.5253 134.962 53.4816C135.228 53.4379 135.501 53.5006 135.721 53.6561C135.941 53.8115 136.091 54.0473 136.139 54.3126C136.395 55.8747 136.598 57.4696 136.739 59.0536C136.913 60.9867 137 62.9567 137 64.909C137 65.0438 136.974 65.1774 136.922 65.3019C136.87 65.4265 136.795 65.5397 136.699 65.635C136.604 65.7304 136.491 65.806 136.366 65.8576C136.242 65.9092 136.108 65.9358 135.973 65.9358Z"
              fill="#4F83E3"
            />
            <path
              d="M78.1805 107.661H22.9821C21.567 107.658 20.2108 107.095 19.2102 106.094C18.2096 105.093 17.6461 103.737 17.6428 102.322V50.89C17.6461 49.475 18.2096 48.1188 19.2102 47.1182C20.2108 46.1176 21.567 45.554 22.9821 45.5508H98.9337C100.503 45.553 102.006 46.1767 103.116 47.2855C104.226 48.3943 104.851 49.8977 104.855 51.4664V69.9485C104.855 70.2208 104.747 70.482 104.554 70.6745C104.362 70.8671 104.1 70.9753 103.828 70.9753C103.556 70.9753 103.295 70.8671 103.102 70.6745C102.909 70.482 102.801 70.2208 102.801 69.9485V51.4664C102.8 50.4409 102.392 49.4577 101.667 48.7324C100.942 48.0071 99.9593 47.5989 98.9337 47.5975H22.9821C22.1116 47.6004 21.2775 47.9475 20.6619 48.563C20.0464 49.1786 19.6993 50.0127 19.6964 50.8832V102.309C19.6993 103.179 20.0464 104.013 20.6619 104.629C21.2775 105.244 22.1116 105.591 22.9821 105.594H78.1805C78.4528 105.594 78.7139 105.702 78.9065 105.895C79.0991 106.088 79.2072 106.349 79.2072 106.621C79.2072 106.893 79.0991 107.154 78.9065 107.347C78.7139 107.54 78.4528 107.648 78.1805 107.648V107.661Z"
              fill="#4F83E3"
            />
            <path
              d="M45.9615 69.9896C44.6347 69.9896 43.3377 69.5961 42.2345 68.859C41.1314 68.1219 40.2715 67.0742 39.7638 65.8484C39.2561 64.6226 39.1232 63.2738 39.3821 61.9725C39.6409 60.6712 40.2798 59.4759 41.218 58.5378C42.1562 57.5996 43.3515 56.9607 44.6528 56.7018C45.954 56.443 47.3028 56.5758 48.5286 57.0836C49.7544 57.5913 50.8021 58.4511 51.5392 59.5543C52.2763 60.6575 52.6698 61.9545 52.6698 63.2813C52.668 65.0598 51.9606 66.7651 50.7029 68.0227C49.4453 69.2804 47.7401 69.9877 45.9615 69.9896ZM45.9615 58.6265C45.0408 58.6265 44.1409 58.8995 43.3754 59.411C42.61 59.9224 42.0134 60.6494 41.661 61.5C41.3087 62.3505 41.2166 63.2864 41.3962 64.1893C41.5758 65.0923 42.0191 65.9217 42.6701 66.5726C43.3211 67.2236 44.1504 67.6669 45.0534 67.8466C45.9563 68.0262 46.8922 67.934 47.7428 67.5817C48.5933 67.2294 49.3203 66.6327 49.8317 65.8673C50.3432 65.1018 50.6162 64.2019 50.6162 63.2813C50.6162 62.0467 50.1258 60.8628 49.2529 59.9898C48.3799 59.1169 47.196 58.6265 45.9615 58.6265Z"
              fill="#4F83E3"
            />
            <path
              d="M34.5984 69.9896C33.2716 69.9896 31.9747 69.5961 30.8715 68.859C29.7683 68.1219 28.9085 67.0742 28.4008 65.8484C27.893 64.6226 27.7602 63.2738 28.019 61.9725C28.2779 60.6712 28.9168 59.4759 29.8549 58.5378C30.7931 57.5996 31.9884 56.9607 33.2897 56.7018C34.591 56.443 35.9398 56.5758 37.1656 57.0836C38.3914 57.5913 39.4391 58.4511 40.1762 59.5543C40.9133 60.6575 41.3067 61.9545 41.3067 63.2813C41.3049 65.0598 40.5976 66.7651 39.3399 68.0227C38.0822 69.2804 36.377 69.9877 34.5984 69.9896ZM34.5984 58.6265C33.6778 58.6265 32.7779 58.8995 32.0124 59.411C31.2469 59.9224 30.6503 60.6494 30.298 61.5C29.9457 62.3505 29.8535 63.2864 30.0331 64.1893C30.2127 65.0923 30.6561 65.9217 31.307 66.5726C31.958 67.2236 32.7874 67.6669 33.6903 67.8466C34.5933 68.0262 35.5292 67.934 36.3797 67.5817C37.2303 67.2294 37.9572 66.6327 38.4687 65.8673C38.9802 65.1018 39.2532 64.2019 39.2532 63.2813C39.2532 62.0467 38.7628 60.8628 37.8898 59.9898C37.0169 59.1169 35.8329 58.6265 34.5984 58.6265Z"
              fill="#4F83E3"
            />
            <path
              opacity="0.8"
              d="M113.509 74.877C113.236 74.877 112.975 74.7689 112.783 74.5763C112.59 74.3837 112.482 74.1226 112.482 73.8502V41.7859C112.482 40.7594 112.074 39.775 111.349 39.0487C110.623 38.3225 109.639 37.914 108.613 37.9129H32.6626C31.792 37.9154 30.9577 38.2624 30.3421 38.8781C29.7264 39.4937 29.3795 40.328 29.3769 41.1986V43.3891C29.3769 43.6614 29.2687 43.9226 29.0762 44.1151C28.8836 44.3077 28.6225 44.4159 28.3501 44.4159C28.0778 44.4159 27.8166 44.3077 27.6241 44.1151C27.4315 43.9226 27.3234 43.6614 27.3234 43.3891V41.2096C27.3266 39.7945 27.8902 38.4383 28.8908 37.4377C29.8914 36.4371 31.2476 35.8736 32.6626 35.8703H108.613C110.183 35.8721 111.688 36.4965 112.798 37.6065C113.909 38.7165 114.533 40.2215 114.535 41.7914V73.853C114.535 74.1248 114.426 74.3853 114.234 74.5773C114.041 74.7692 113.78 74.877 113.509 74.877Z"
              fill="#4F83E3"
            />
            <path
              d="M122.598 85.2625C122.325 85.2625 122.064 85.1544 121.872 84.9618C121.679 84.7692 121.571 84.5081 121.571 84.2358V31.7522C121.57 30.7268 121.162 29.7437 120.437 29.0186C119.712 28.2935 118.729 27.8857 117.703 27.8846H41.7516C40.881 27.8872 40.0467 28.2342 39.4311 28.8498C38.8154 29.4654 38.4684 30.2997 38.4659 31.1703V32.9994C38.4659 33.2717 38.3577 33.5329 38.1652 33.7254C37.9726 33.918 37.7115 34.0262 37.4391 34.0262C37.1668 34.0262 36.9057 33.918 36.7131 33.7254C36.5205 33.5329 36.4124 33.2717 36.4124 32.9994V31.1758C36.4156 29.7608 36.9792 28.4046 37.9798 27.404C38.9804 26.4034 40.3366 25.8398 41.7516 25.8365H117.703C119.272 25.8384 120.776 26.462 121.886 27.5709C122.996 28.6797 123.621 30.1833 123.624 31.7522V84.2358C123.624 84.5081 123.516 84.7692 123.324 84.9618C123.131 85.1544 122.87 85.2625 122.598 85.2625Z"
              fill="#4F83E3"
            />
            <path
              d="M44.8362 87.0327H28.4241C28.1518 87.0327 27.8906 86.9246 27.6981 86.732C27.5055 86.5394 27.3973 86.2783 27.3973 86.006C27.3973 85.7336 27.5055 85.4725 27.6981 85.2799C27.8906 85.0874 28.1518 84.9792 28.4241 84.9792H44.8362C45.1085 84.9792 45.3696 85.0874 45.5622 85.2799C45.7548 85.4725 45.863 85.7336 45.863 86.006C45.863 86.2783 45.7548 86.5394 45.5622 86.732C45.3696 86.9246 45.1085 87.0327 44.8362 87.0327Z"
              fill="#4F83E3"
            />
            <path
              d="M44.8362 96.7119H28.4241C28.1518 96.7119 27.8906 96.6037 27.6981 96.4112C27.5055 96.2186 27.3973 95.9574 27.3973 95.6851C27.3973 95.4128 27.5055 95.1516 27.6981 94.9591C27.8906 94.7665 28.1518 94.6583 28.4241 94.6583H44.8362C45.1085 94.6583 45.3696 94.7665 45.5622 94.9591C45.7548 95.1516 45.863 95.4128 45.863 95.6851C45.863 95.9574 45.7548 96.2186 45.5622 96.4112C45.3696 96.6037 45.1085 96.7119 44.8362 96.7119Z"
              fill="#4F83E3"
            />
            <path
              d="M69.3858 87.0327H53.6856C53.4133 87.0327 53.1521 86.9246 52.9596 86.732C52.767 86.5394 52.6588 86.2783 52.6588 86.006C52.6588 85.7336 52.767 85.4725 52.9596 85.2799C53.1521 85.0874 53.4133 84.9792 53.6856 84.9792H69.3858C69.6581 84.9792 69.9193 85.0874 70.1118 85.2799C70.3044 85.4725 70.4126 85.7336 70.4126 86.006C70.4126 86.2783 70.3044 86.5394 70.1118 86.732C69.9193 86.9246 69.6581 87.0327 69.3858 87.0327Z"
              fill="#4F83E3"
            />
            <path
              d="M69.3858 96.7119H53.6856C53.4133 96.7119 53.1521 96.6037 52.9596 96.4112C52.767 96.2186 52.6588 95.9574 52.6588 95.6851C52.6588 95.4128 52.767 95.1516 52.9596 94.9591C53.1521 94.7665 53.4133 94.6583 53.6856 94.6583H69.3858C69.6581 94.6583 69.9193 94.7665 70.1118 94.9591C70.3044 95.1516 70.4126 95.4128 70.4126 95.6851C70.4126 95.9574 70.3044 96.2186 70.1118 96.4112C69.9193 96.6037 69.6581 96.7119 69.3858 96.7119Z"
              fill="#4F83E3"
            />
            <path
              d="M99.1871 113.416C88.4018 113.416 79.6276 104.641 79.6276 93.856C79.6276 83.0707 88.4018 74.2965 99.1871 74.2965C109.972 74.2965 118.748 83.0707 118.748 93.856C118.748 104.641 109.976 113.416 99.1871 113.416ZM99.1871 76.3501C95.7248 76.3503 92.3403 77.3773 89.4617 79.301C86.583 81.2248 84.3394 83.9589 83.0147 87.1577C81.6899 90.3565 81.3434 93.8763 82.019 97.2721C82.6947 100.668 84.362 103.787 86.8104 106.235C89.2587 108.683 92.3779 110.35 95.7737 111.026C99.1695 111.701 102.689 111.354 105.888 110.029C109.087 108.704 111.821 106.46 113.744 103.582C115.668 100.703 116.694 97.3183 116.694 93.856C116.69 89.2144 114.844 84.7642 111.561 81.4822C108.279 78.2001 103.829 76.3544 99.1871 76.3501Z"
              fill="#4F83E3"
            />
            <path
              d="M99.1871 105.152C98.9147 105.152 98.6536 105.044 98.461 104.851C98.2685 104.659 98.1603 104.398 98.1603 104.125V83.5896C98.1603 83.3173 98.2685 83.0561 98.461 82.8635C98.6536 82.671 98.9147 82.5628 99.1871 82.5628C99.4594 82.5628 99.7205 82.671 99.9131 82.8635C100.106 83.0561 100.214 83.3173 100.214 83.5896V104.125C100.214 104.26 100.188 104.394 100.136 104.518C100.085 104.643 100.009 104.756 99.9135 104.852C99.8181 104.947 99.7048 105.023 99.5802 105.074C99.4555 105.126 99.3219 105.152 99.1871 105.152Z"
              fill="#4F83E3"
            />
            <path
              d="M109.462 94.8828H88.9194C88.647 94.8828 88.3859 94.7746 88.1933 94.5821C88.0008 94.3895 87.8926 94.1283 87.8926 93.856C87.8926 93.5837 88.0008 93.3225 88.1933 93.13C88.3859 92.9374 88.647 92.8292 88.9194 92.8292H109.455C109.727 92.8292 109.988 92.9374 110.181 93.13C110.374 93.3225 110.482 93.5837 110.482 93.856C110.482 94.1283 110.374 94.3895 110.181 94.5821C109.988 94.7746 109.727 94.8828 109.455 94.8828H109.462Z"
              fill="#4F83E3"
            />
          </g>
          <defs>
            <clipPath id="clip0_3067_22197">
              <rect
                width="137"
                height="129.817"
                fill="white"
              />
            </clipPath>
          </defs>
        </svg>

        <p class="text-center text-[20px] leading-[28px] font-[600] text-[#242424] mb-0">
          You Have No Active Payment Method
        </p>

        <b-btn
          variant="link"
          class="text-[16px] leading-[24px] font-[400]"
          @click="modal = true"
        >
          Add a payment Method
        </b-btn>
      </div>
      
      <transition
        v-else
        name="fade"
      >
        <div v-if="!loading">
          <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-x-[24px] gap-y-[12px]">
            <div
              v-for="({id, card, billing_details: {name: name}}, index) in methods"
              :key="index"
            >
              <card
                :method="{id, ...card, name}"
                :primary="primaryMethod"
                @refresh="fetchPaymentMethods"
              />
            </div>
            <button 
              class="h-[256px] flex flex-col p-[24px] gap-y-[16px] items-center justify-center border border-[#C2D8FF] bg-[#FBFCFF] hover:bg-[rgba(194,216,255,.25)] rounded-[8px] text-[#4F83E3] text-[14px] leading-[20px] font-[400] shadow-[0_3px_9px_0_rgba(0,0,0,0.05)]"
              @click="modal = true"
            >
              <svg
                width="30"
                height="30"
                viewBox="0 0 30 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="w-[24px] h-[24px] md:w-[32px] md:h-[32px]"
              >
                <mask
                  id="path-1-outside-1_3065_21467"
                  maskUnits="userSpaceOnUse"
                  x="9"
                  y="9"
                  width="12"
                  height="12"
                  fill="black"
                >
                  <rect
                    fill="white"
                    x="9"
                    y="9"
                    width="12"
                    height="12"
                  />
                  <path d="M20.208 15.416V14.408H15.504V9.344H14.424V14.408H9.696V15.416H14.424V20.624H15.504V15.416H20.208Z" />
                </mask>
                <path
                  d="M20.208 15.416V14.408H15.504V9.344H14.424V14.408H9.696V15.416H14.424V20.624H15.504V15.416H20.208Z"
                  fill="#4F83E3"
                />
                <path
                  d="M20.208 15.416V15.616H20.408V15.416H20.208ZM20.208 14.408H20.408V14.208H20.208V14.408ZM15.504 14.408H15.304V14.608H15.504V14.408ZM15.504 9.344H15.704V9.144H15.504V9.344ZM14.424 9.344V9.144H14.224V9.344H14.424ZM14.424 14.408V14.608H14.624V14.408H14.424ZM9.696 14.408V14.208H9.496V14.408H9.696ZM9.696 15.416H9.496V15.616H9.696V15.416ZM14.424 15.416H14.624V15.216H14.424V15.416ZM14.424 20.624H14.224V20.824H14.424V20.624ZM15.504 20.624V20.824H15.704V20.624H15.504ZM15.504 15.416V15.216H15.304V15.416H15.504ZM20.408 15.416V14.408H20.008V15.416H20.408ZM20.208 14.208H15.504V14.608H20.208V14.208ZM15.704 14.408V9.344H15.304V14.408H15.704ZM15.504 9.144H14.424V9.544H15.504V9.144ZM14.224 9.344V14.408H14.624V9.344H14.224ZM14.424 14.208H9.696V14.608H14.424V14.208ZM9.496 14.408V15.416H9.896V14.408H9.496ZM9.696 15.616H14.424V15.216H9.696V15.616ZM14.224 15.416V20.624H14.624V15.416H14.224ZM14.424 20.824H15.504V20.424H14.424V20.824ZM15.704 20.624V15.416H15.304V20.624H15.704ZM15.504 15.616H20.208V15.216H15.504V15.616Z"
                  fill="#4F83E3"
                  mask="url(#path-1-outside-1_3065_21467)"
                />
                <circle
                  cx="15"
                  cy="15"
                  r="14.25"
                  stroke="#4F83E3"
                  stroke-width="1.5"
                />
              </svg>
    
              Add a Secondary Payment Method
            </button>
          </div>
        </div>
      </transition>
    </div>

    <div class="grid grid-cols-1 lg:grid-cols-2 gap-[56px]">
      <div
        v-if="history.length"
        class="flex flex-col gap-[32px]"
      >
        <h2 class="text-[22px] leading-[26px] font-[600]">
          Billing History
        </h2>

        <billing-history-table :data="history" />
      </div>

      <div
        v-if="!upcomingIsEmpty"
        class="flex flex-col gap-[32px]"
      >
        <h2 class="text-[22px] leading-[26px] font-[600]">
          Upcoming Payments
        </h2>

        <upcoming-payments-table :data="upcoming" />
      </div>
    </div>
    
    <add-method-modal
      v-model="modal"
      :set-primary="methods.length === 0"
      @added="onPaymentMethodAdded"
    />
    <hold-account-modal
      v-model="onHoldModal"
      on-hold
    />
  </div>
</template>

<script>
import _ from 'lodash'
import {mapGetters} from 'vuex'
import AccountOverview from '@/layouts/dashboard/compontens/account/billing/components/AccountOverview.vue'
import ModifyPlan from '@/layouts/dashboard/compontens/account/billing/components/ModifyPlan.vue'
import Card from '@/layouts/dashboard/compontens/account/billing/components/Card.vue'
import AddMethodModal from '@/layouts/dashboard/compontens/account/billing/components/AddMethodModal.vue'
import BillingHistoryTable from './components/BillingHistoryTable'
import HoldAccountModal from '@/layouts/dashboard/compontens/account/billing/components/HoldAccountModal.vue'
import UpcomingPaymentsTable from '@/layouts/dashboard/compontens/account/billing/components/UpcomingPaymentsTable.vue'
export default {
  name: 'Main',
  components: {
    HoldAccountModal,
    ModifyPlan,
    AccountOverview,
    AddMethodModal,
    Card,
    BillingHistoryTable,
    UpcomingPaymentsTable
  },
  data () {
    return {
      loading: false,
      reload: false,
      modal: false,
      onHoldModal: false,
      methods: [],
      primaryMethod: null,
      modalData: null,
      history: [],
      upcoming: {},
      promo: {
        title: 'Claim your 2 Free Months',
        description: 'Upgrade to Annual Plan and get 2 Months Free',
        cta: 'Claim Now'
      }
    }
  },
  computed: {
    ...mapGetters('auth', {
      user: 'user',
      userSubscription: 'userSubscription'
    }),
    isAnnualPlan () {
      return _.includes(['BUSINESS_PLUS_Y', 'BUSINESS_Y'], this.userSubscription.plan.code)
    },
    isBusinessPlusPlan () {
      return _.includes(['BUSINESS_PLUS_Y', 'BUSINESS_PLUS_M'], this.userSubscription.plan.code)
    },
    isSpecialPlan () {
      return !_.includes(['ESTAGE_SUB', 'BUSINESS_Y', 'BUSINESS_PLUS_M', 'BUSINESS_PLUS_Y'], this.userSubscription.plan.code)
    },
    interval () {
      return this.userSubscription.plan.interval
    },
    upcomingIsEmpty () {
      return _.isEmpty(this.upcoming)
    }
  },
  methods: {
    async onPaymentMethodAdded () {
      await this.fetchPaymentMethods()
    },
    async fetchPaymentMethods () {
      this.loading = true
      const {data} = await axios.get('api/user/settings/payment-methods')
      this.primaryMethod = data.data.user?.invoice_settings?.default_payment_method
      this.methods = data.data.cards
      this.loading = false
    },
    fetchHistory () {
      axios.get('api/user/settings/payment-methods/history')
        .then(({data}) => {
          this.history = data.data.invoices
          this.upcoming = data.data.upcoming_invoice
        })
        .catch((error) => {
          console.error(error)
        })
    }
  },
  async created () {
    this.onHoldModal = this.user.next_change_billing !== null && this.user.status === 'succeeded'
    await this.fetchPaymentMethods()
    await this.fetchHistory()
  }
}
</script>