<template>
  <div class="flex flex-col">
    <template v-if="activeStep === 0">
      <h4 class="text-center text-[3.75rem] text-black leading-none -tracking-[.0625rem] font-[800] font-[ProximaNova] mb-[0]">
        Activate Your Estage <br> Business Account
      </h4>

      <p class="text-center text-[1.1875rem] text-[#5B6474] leading-[2rem] font-[400] font-[ProximaNova] mt-[1.5rem] mb-[0]">
        As a new member of Affiliate Business System, you get 30-day free access to ESTAGE business account!
      </p>
    </template>

    <div class="flex flex-col items-center justify-center w-[600px] mt-[2.25rem] px-[2.5rem] py-[2rem] bg-white rounded-[.625rem] drop-shadow-[0,4px,34px,0,rgba(0,0,0,0.06)]">
      <div class="flex flex-col justify-center text-center gap-y-[.5rem]">
        <template v-if="activeStep !== 2">
          <span class="text-[.875rem] text-[#7F8897] leading-[1.25rem] font-[400] font-[ProximaNova]">
            Step {{ activeStep + 1 }} of 2
          </span>
          <h4
            v-if="activeStep === 0"
            class="text-[1.6875rem] text-[#000000] leading-[2rem] font-[700] font-[ProximaNova]"
          >
            Start your 30-day Free Trial Now!
          </h4>
          <h4
            v-else-if="activeStep === 1"
            class="text-[1.6875rem] text-[#000000] leading-[2rem] font-[700] font-[ProximaNova]"
          >
            Select Your Plan
          </h4>
        </template>
        <template v-else>
          <h4 class="text-[1.6875rem] text-[#000000] leading-[2rem] font-[400] font-[ProximaNova]">
            <strong class="font-[700] mb-[.25rem]">Congratulations,</strong> <br>
            Your ESTAGE Account is Now Active!
          </h4>
        </template>
      </div>

      <b-form
        class="welcome-step-form w-full mt-[1.5rem]"
        @submit.prevent="submit"
      >
        <FirstStep 
          v-if="activeStep === 0" 
          :form="form"
        />
        <AccountDetailStep 
          v-if="activeStep === 1"
          :form="form"
          @change-plan="changePlan"
        />
        <SuccessStep 
          v-if="activeStep === 2"
          :form="form"
        />

        <b-btn
          v-if="activeStep !== 2"
          class="!inline-flex items-center justify-center text-center gap-[.5rem] w-full h-[5rem] !text-[1.75rem] !leading-[2rem] !font-[700] text-white mt-[1.25rem] rounded-[.25rem] hover:opacity-80 !bg-[#4989EC]"
          variant="primary"
          type="submit"
          :disabled="loading || $validator.errors.any()"
        >
          {{ submitButtonText() }}
          <svg
            v-if="!loading"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5 12H19M19 12L12 5M19 12L12 19"
              stroke="white"
              stroke-width="4"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <b-spinner
            v-else
            variant="white"
            small
          />
        </b-btn>
        
        <a 
          v-else
          :href="`https://app.estage.com?auth_token=${access_token}`"
          target="_blank"
          class="!inline-flex items-center justify-center text-center gap-[.5rem] w-full h-[5rem] !text-[1.75rem] !leading-[2rem] !font-[700] text-white no-underline mt-[1.25rem] rounded-[.25rem] hover:opacity-80 !bg-[#4989EC]"
        >
          Click here to Login to Estage
        </a>

        <!--        <p-->
        <!--          v-if="activeStep === 0"-->
        <!--          class="text-center text-[.875rem] text-[#8A8A8A] leading-[1.125rem] font-[400] font-[ProximaNova] mt-[.875rem] mb-[0] px-[1.25rem]"-->
        <!--        >-->
        <!--          By proceeding you agree to our-->
        <!--          <a -->
        <!--            class="text-[#3D82EA] hover:underline"-->
        <!--            href="https://estage.com"-->
        <!--            target="_blank"-->
        <!--          >-->
        <!--            terms-->
        <!--          </a> and-->
        <!--          <a-->
        <!--            class="text-[#3D82EA] hover:underline"-->
        <!--            href="https://estage.com"-->
        <!--            target="_blank"-->
        <!--          >-->
        <!--            privacy policy-->
        <!--          </a>-->
        <!--        </p>-->

        <div
          v-if="activeStep === 1"
          class="flex flex-col mt-[.875rem] gap-[.625rem] px-[1.25rem]"
        >
          <span class="text-center text-[.875rem] text-[#464646] leading-[1.125rem] font-[400] font-[ProximaNova] mb-[0]">
            No charges will be processed today
          </span>
          <p class="text-center text-[.875rem] text-[#8A8A8A] leading-[1.125rem] font-[400] font-[ProximaNova] mb-[0]">
            30 days free from today, then just {{ planAmount }} (plus applicable taxes). Cancel through your dashboard or by e-mail (support@estage.com).
            <!--            I agree to the-->
            <!--            <a-->
            <!--              class="text-[#3D82EA] hover:underline"-->
            <!--              href="https://estage.com"-->
            <!--              target="_blank"-->
            <!--            >-->
            <!--              Terms of Service,-->
            <!--            </a>-->
            <!--            <a-->
            <!--              class="text-[#3D82EA] hover:underline"-->
            <!--              href="https://estage.com"-->
            <!--              target="_blank"-->
            <!--            >-->
            <!--              Privacy Policy-->
            <!--            </a>-->
          </p>
        </div>
      </b-form>
    </div>
  </div>
</template>

<script>
import FirstStep from '@/components/editor/components/step-form/welcome/steps/FirstStep.vue'
import AccountDetailStep from '@/components/editor/components/step-form/welcome/steps/AccountDetailsStep.vue'
import SuccessStep from '@/components/editor/components/step-form/welcome/steps/SuccessStep.vue'
export default {
  name: 'WelcomeStepForm',
  inject: {
    $validator: '$validator'
  },
  components: {
    SuccessStep,
    FirstStep,
    AccountDetailStep
  },
  data () {
    return {
      loading: false,
      activeStep: 0,
      plan: 'BUSINESS_Y',
      access_token: null,
      form: {
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        password: '',
        password_confirmation: '',
        cc_number: '',
        cc_exp_month: '',
        cc_exp_year: '',
        cc_cvc: '',
        name: '',
        country: '',
        zip_code: ''
      }
    }
  },
  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    planAmount () {
      switch (this.plan) {
      case 'BUSINESS_PLUS_Y':
        return '$2970/year'
      case 'BUSINESS_PLUS_M':
        return '$297/month'
      case 'BUSINESS_Y':
        return '$1970/year'
      case 'ESTAGE_SUB':
        return '$197/month'
      }
    }
  },
  created () {
    this.fetchUserData()
  },
  methods: {
    fetchUserData () {
      axios.get(`api/auth/welcome/user/${this.$route.query.user}`)
        .then(({data}) => {
          this.form = {
            first_name: data.data.first_name,
            last_name: data.data.last_name,
            email: data.data.email
          }
        })
    },
    changePlan (plan) {
      this.plan = plan
    },
    submitButtonText () {
      switch (this.activeStep) {
      case 0:
        return 'Continue to Step 2'
      case 1:
        return 'Activate My Account'
      case 2:
        return 'Click here to Login to Estage'
      }
    },
    addCard (cardToken) {
      return new Promise((resolve, reject) => {
        axios.post('api/auth/welcome/card', {
          stripe_card_token: cardToken,
          user_hash: this.$route.query.user,
          open_password: this.form.password,
          plan: this.plan
        })
          .then(() => {
            resolve()
          })
          .catch((err) => {
            this.$swal({
              icon: 'error',
              iconColor: '#e34f5b',
              title: 'Payment canceled',
              text: 'Please check your card details and try again',
              showConfirmButton: false,
              timer: 3000
            })
            reject(err)
          })
      })
    },
    register () {
      return new Promise((resolve, reject) => {
        axios.post('api/auth/welcome', {
          hash: this.$route.query.user,
          first_name: this.form.first_name,
          last_name: this.form.last_name,
          email: this.form.email,
          password: this.form.password,
          password_confirmation: this.form.password_confirmation
        })
          .then(({data}) => {
            this.access_token = data.data.access_token
            resolve()
          })
          .catch((err) => {
            this.$swal({
              icon: 'error',
              iconColor: '#e34f5b',
              title: 'Registration failed',
              text: 'Please check your entered data and try again',
              showConfirmButton: false,
              timer: 3000
            })
            reject(err)
          })
      })
    },
    submit () {
      if (this.activeStep === 0) {
        let valid = false
        this.$children.forEach((vm) => {
          vm.$validator.validateAll()
            .then((v) => {
              valid = v
              if (valid) {
                this.loading = true
                this.activeStep++
                this.loading = false
              }
            })
        })
      } else if (this.activeStep === 1) {
        let valid = false
        this.$children.forEach((vm) => {
          vm.$validator.validateAll()
            .then((v) => {
              valid = v
              if (valid) {
                this.loading = true
                // eslint-disable-next-line no-undef
                Stripe.setPublishableKey(process.env.VUE_APP_STRIPE_KEY)
                
                // eslint-disable-next-line no-undef
                Stripe.createToken({
                  number: this.form.cc_number,
                  cvc: this.form.cc_cvc,
                  exp_month: this.form.cc_exp_month,
                  exp_year: this.form.cc_exp_year,
                  name: `${this.form.first_name} ${this.form.last_name}`,
                  address_country: this.form.country,
                  address_zip: this.form.zip_code
                }, (status, response) => {
                  if (status !== 200) {
                    this.$swal({
                      icon: 'error',
                      title: 'Payment Declined',
                      text: response.error.message,
                      showConfirmButton: false,
                      timer: 3500
                    })
                  }

                  this.addCard(response.id)
                    .then(() => {
                      this.register()
                        .then(() => {
                          this.activeStep++
                        })
                        .catch(() => {
                          this.loading = false
                        })
                        .finally(() => {
                          this.loading = false
                        })
                    })
                    .catch(() => {
                      this.loading = false
                    })
                })

              }
            })
        })
      }
    }
  }
}
</script>

<style lang="scss">
.welcome-step-form {
  .form-group {
    .form-control {
      font-size: 1rem;
      line-height: 1;
      font-weight: 400;
      font-family: ProximaNova;
      color: #000000;
      background-color: #FFFFFF;
      border: 1px solid #D0D5DD;
      border-radius: .25rem;
      padding: .625rem .875rem;
      height: 2.5rem;
      box-shadow: 0 1px 2px 0 rgb(16 24 40 / 0.05);

      &:active {
        color: #464646;
      }

      &::placeholder {
        color: #464646;
      }
    }
  }
}
</style>