<template>
  <div>
    <module-editor
      v-if="!loading"
      ref="editor"
      v-model="blogTemplate.content"
      crumb-title="Single Post"
      aside-title="Single Post"
      group="blocks"
      :draggable="true"
      :show-save="false"
      :show-warn-modal="showWarnModal"
      @save="() => onSave({status_code: 'publish'})"
    >
      <template #header-before>
        <button
          class="flex disabled:opacity-60 items-center rounded-left justify-center h-[36px] w-[41px] border bg-white p-0"
          :disabled="!isActiveUndo"
          @click="undo"
        >
          <svg
            width="17"
            height="11"
            viewBox="0 0 17 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          ><path
            d="M16.4649 10.7396C16.6349 10.704 16.7547 10.5567 16.7547 10.3867C16.7547 4.51518 9.7847 3.26446 8.16565 3.05318V0.35908C8.16565 0.226318 8.0928 0.10489 7.97541 0.0409374C7.85641 -0.0205864 7.71556 -0.0116817 7.60627 0.0603659L0.157034 5.07456C0.059082 5.14094 -1.52588e-05 5.25103 -1.52588e-05 5.37327C-1.52588e-05 5.49227 0.059082 5.60318 0.157034 5.66956L7.60222 10.6829C7.71313 10.7558 7.8556 10.7639 7.97218 10.7016C8.09037 10.6384 8.16322 10.5162 8.16322 10.3826V7.48451C9.3346 7.49179 10.3044 7.5436 11.1115 7.64075C14.9851 8.09975 16.0205 10.4255 16.0626 10.5251C16.12 10.6587 16.2512 10.7445 16.3945 10.7445C16.4187 10.7461 16.443 10.7445 16.4649 10.7396Z"
            fill="#6E747A"
          /></svg>
        </button>
        <button
          class="flex disabled:opacity-60 items-center rounded-right border-left-0 justify-center h-[36px] w-[41px] border bg-white p-0"
          :disabled="!isActiveRedo"
          @click="redo"
        >
          <svg
            width="17"
            height="11"
            viewBox="0 0 17 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          ><path
            d="M0.28981 10.7396C0.11981 10.704 0 10.5567 0 10.3867C0 4.51518 6.97 3.26446 8.58905 3.05318V0.35908C8.58905 0.226318 8.6619 0.10489 8.77929 0.0409374C8.89829 -0.0205864 9.03914 -0.0116817 9.14843 0.0603659L16.5977 5.07456C16.6956 5.14094 16.7547 5.25103 16.7547 5.37327C16.7547 5.49227 16.6956 5.60318 16.5977 5.66956L9.15248 10.6829C9.04157 10.7558 8.8991 10.7639 8.78252 10.7016C8.66433 10.6384 8.59148 10.5162 8.59148 10.3826V7.48451C7.4201 7.49179 6.45029 7.5436 5.64319 7.64075C1.76962 8.09975 0.734238 10.4255 0.692143 10.5251C0.634667 10.6587 0.503524 10.7445 0.360238 10.7445C0.335952 10.7461 0.311667 10.7445 0.28981 10.7396Z"
            fill="#6E747A"
          /></svg>
        </button>
      </template>

      <template #header-after>
        <b-btn-group class="h-[34px] ml-3">
          <b-btn
            v-b-tooltip="{title: 'Save'}"
            variant="white"
            class="btn-with-icon no-focus on-save px-3"
            :class="{'border-right-0': post.status_code === 'publish'}"
            @click="onDraft"
          >
            <svg
              width="15"
              height="15"
              viewBox="0 0 15 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.8404 0H1.75781C0.788555 0 0 0.788555 0 1.75781V13.2422C0 14.2114 0.788555 15 1.75781 15H13.2422C14.2114 15 15 14.2114 15 13.2422V2.15965L12.8404 0ZM3.51562 1.17188H10.2539V4.62891H3.51562V1.17188ZM11.4844 13.8281H3.51562V9.19922H11.4844V13.8281ZM13.8281 13.2422C13.8281 13.5653 13.5653 13.8281 13.2422 13.8281H12.6562V8.02734H2.34375V13.8281H1.75781C1.43473 13.8281 1.17188 13.5653 1.17188 13.2422V1.75781C1.17188 1.43473 1.43473 1.17188 1.75781 1.17188H2.34375V5.80078H11.4258V1.17188H12.355L13.8281 2.64504V13.2422Z"
                fill="#6E747A"
              />
              <path
                d="M9.19922 1.72852H8.02734V4.04297H9.19922V1.72852Z"
                fill="#6E747A"
              />
            </svg>
          </b-btn>
          <!--          <b-btn-->
          <!--            v-if="post.status_code === 'publish'"-->
          <!--            v-b-tooltip="{title: 'Preview'}"-->
          <!--            variant="white"-->
          <!--            class="btn-with-icon no-focus on-save px-3"-->
          <!--            :href="`https://${project.domain || project.subdomain + '.estage.site'}/blog/${post.slug}`"-->
          <!--            target="_blank"-->
          <!--          >-->
          <!--            <icon icon="eye-3.svg" />-->
          <!--          </b-btn>-->
          <b-dropdown
            v-b-tooltip="{customClass: 'on-small-screen', title: 'Publish'}"
            variant="primary"
            menu-class="w-[200px]"
            right
          >
            <template #button-content>
              <svg
                class="w-4 h-4"
                width="17"
                height="15"
                viewBox="0 0 17 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.49998 1.83333C6.54397 1.83333 4.95831 3.41899 4.95831 5.375C4.95831 5.7662 4.64118 6.08333 4.24998 6.08333C2.98457 6.08333 2.12498 6.96205 2.12498 7.85417C2.12498 8.74628 2.98457 9.625 4.24998 9.625C4.64118 9.625 4.95831 9.94213 4.95831 10.3333C4.95831 10.7245 4.64118 11.0417 4.24998 11.0417C2.38578 11.0417 0.708313 9.70046 0.708313 7.85417C0.708313 6.23132 2.00432 4.9987 3.58398 4.72412C3.90271 2.2933 5.98219 0.416668 8.49998 0.416668C11.0178 0.416668 13.0972 2.2933 13.416 4.72412C14.9956 4.9987 16.2916 6.23132 16.2916 7.85417C16.2916 9.70046 14.6142 11.0417 12.75 11.0417C12.3588 11.0417 12.0416 10.7245 12.0416 10.3333C12.0416 9.94213 12.3588 9.625 12.75 9.625C14.0154 9.625 14.875 8.74628 14.875 7.85417C14.875 6.96205 14.0154 6.08333 12.75 6.08333C12.3588 6.08333 12.0416 5.7662 12.0416 5.375C12.0416 3.41899 10.456 1.83333 8.49998 1.83333Z"
                  fill="white"
                />
                <path
                  d="M11.1258 8.7092C10.8492 8.98582 10.4007 8.98582 10.1241 8.7092L9.20831 7.7934V13.875C9.20831 14.2662 8.89118 14.5833 8.49998 14.5833C8.10878 14.5833 7.79165 14.2662 7.79165 13.875V7.7934L6.87585 8.7092C6.59923 8.98582 6.15073 8.98582 5.87411 8.7092C5.59749 8.43258 5.59749 7.98409 5.87411 7.70747L7.99911 5.58247C8.13195 5.44963 8.31212 5.375 8.49998 5.375C8.68784 5.375 8.86801 5.44963 9.00085 5.58247L11.1258 7.70747C11.4025 7.98409 11.4025 8.43258 11.1258 8.7092Z"
                  fill="white"
                />
              </svg>

              <span class="d-none d-xxl-block ml-2">Publish</span>
            </template>
            <div
              class="flex items-center px-[16px] py-[12px] text-[14px] leading-[18px] text-[#6E747A] cursor-pointer hover:bg-[#FAFCFF] hover:text-[#4F83E3]"
              @click="onPublish({status_code: 'publish'})"
            >
              <svg
                width="17"
                height="15"
                viewBox="0 0 17 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="mr-2 w-4 h-4"
              >
                <path
                  d="M8.49998 1.83333C6.54397 1.83333 4.95831 3.41899 4.95831 5.375C4.95831 5.7662 4.64118 6.08333 4.24998 6.08333C2.98457 6.08333 2.12498 6.96205 2.12498 7.85417C2.12498 8.74628 2.98457 9.625 4.24998 9.625C4.64118 9.625 4.95831 9.94213 4.95831 10.3333C4.95831 10.7245 4.64118 11.0417 4.24998 11.0417C2.38578 11.0417 0.708313 9.70046 0.708313 7.85417C0.708313 6.23132 2.00432 4.9987 3.58398 4.72412C3.90271 2.2933 5.98219 0.416668 8.49998 0.416668C11.0178 0.416668 13.0972 2.2933 13.416 4.72412C14.9956 4.9987 16.2916 6.23132 16.2916 7.85417C16.2916 9.70046 14.6142 11.0417 12.75 11.0417C12.3588 11.0417 12.0416 10.7245 12.0416 10.3333C12.0416 9.94213 12.3588 9.625 12.75 9.625C14.0154 9.625 14.875 8.74628 14.875 7.85417C14.875 6.96205 14.0154 6.08333 12.75 6.08333C12.3588 6.08333 12.0416 5.7662 12.0416 5.375C12.0416 3.41899 10.456 1.83333 8.49998 1.83333Z"
                  fill="#6E747A"
                />
                <path
                  d="M11.1258 8.7092C10.8492 8.98582 10.4007 8.98582 10.1241 8.7092L9.20831 7.7934V13.875C9.20831 14.2662 8.89118 14.5833 8.49998 14.5833C8.10878 14.5833 7.79165 14.2662 7.79165 13.875V7.7934L6.87585 8.7092C6.59923 8.98582 6.15073 8.98582 5.87411 8.7092C5.59749 8.43258 5.59749 7.98409 5.87411 7.70747L7.99911 5.58247C8.13195 5.44963 8.31212 5.375 8.49998 5.375C8.68784 5.375 8.86801 5.44963 9.00085 5.58247L11.1258 7.70747C11.4025 7.98409 11.4025 8.43258 11.1258 8.7092Z"
                  fill="#6E747A"
                />
              </svg>
              Publish
            </div>
            <div
              class="flex items-center px-[16px] py-[12px] text-[14px] leading-[18px] text-[#6E747A] cursor-pointer hover:bg-[#FAFCFF] hover:text-[#4F83E3]"
              @click="() => modalSchedule = true"
            >
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="mr-2 w-4 h-4"
              >
                <path
                  d="M4.66602 9.33329C5.03421 9.33329 5.33268 9.03482 5.33268 8.66663C5.33268 8.29844 5.03421 7.99996 4.66602 7.99996C4.29783 7.99996 3.99935 8.29844 3.99935 8.66663C3.99935 9.03482 4.29783 9.33329 4.66602 9.33329Z"
                  fill="#6E747A"
                />
                <path
                  d="M7.99935 8.66663C7.99935 9.03482 7.70087 9.33329 7.33268 9.33329C6.96449 9.33329 6.66602 9.03482 6.66602 8.66663C6.66602 8.29844 6.96449 7.99996 7.33268 7.99996C7.70087 7.99996 7.99935 8.29844 7.99935 8.66663Z"
                  fill="#6E747A"
                />
                <path
                  d="M9.99935 9.33329C10.3675 9.33329 10.666 9.03482 10.666 8.66663C10.666 8.29844 10.3675 7.99996 9.99935 7.99996C9.63116 7.99996 9.33268 8.29844 9.33268 8.66663C9.33268 9.03482 9.63116 9.33329 9.99935 9.33329Z"
                  fill="#6E747A"
                />
                <path
                  d="M5.33268 11.3333C5.33268 11.7015 5.03421 12 4.66602 12C4.29783 12 3.99935 11.7015 3.99935 11.3333C3.99935 10.9651 4.29783 10.6666 4.66602 10.6666C5.03421 10.6666 5.33268 10.9651 5.33268 11.3333Z"
                  fill="#6E747A"
                />
                <path
                  d="M7.33268 12C7.70087 12 7.99935 11.7015 7.99935 11.3333C7.99935 10.9651 7.70087 10.6666 7.33268 10.6666C6.96449 10.6666 6.66602 10.9651 6.66602 11.3333C6.66602 11.7015 6.96449 12 7.33268 12Z"
                  fill="#6E747A"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M5.33268 1.33329C5.33268 0.965103 5.03421 0.666626 4.66602 0.666626C4.29783 0.666626 3.99935 0.965103 3.99935 1.33329V1.99996H2.66602C1.56145 1.99996 0.666016 2.89539 0.666016 3.99996V12.6666C0.666016 13.7712 1.56145 14.6666 2.66602 14.6666H13.3327C14.4373 14.6666 15.3327 13.7712 15.3327 12.6666V3.99996C15.3327 2.89539 14.4373 1.99996 13.3327 1.99996H11.9993V1.33329C11.9993 0.965103 11.7009 0.666626 11.3327 0.666626C10.9645 0.666626 10.666 0.965103 10.666 1.33329V1.99996H5.33268V1.33329ZM1.99935 3.99996C1.99935 3.63177 2.29783 3.33329 2.66602 3.33329H13.3327C13.7009 3.33329 13.9993 3.63177 13.9993 3.99996V5.33329H1.99935V3.99996ZM1.99935 6.66663H13.9993V12.6666C13.9993 13.0348 13.7009 13.3333 13.3327 13.3333H2.66602C2.29783 13.3333 1.99935 13.0348 1.99935 12.6666V6.66663Z"
                  fill="#6E747A"
                />
              </svg>
              Schedule Post
            </div>
            <b-dropdown-divider />
            <div class="flex items-center px-[16px] py-[8px] text-[14px] leading-[18px] text-[#6E747A] font-[400]">
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="mr-2 w-4 h-4"
              >
                <path
                  d="M5.25 9.75C5.25 9.33579 5.58579 9 6 9H12C12.4142 9 12.75 9.33579 12.75 9.75C12.75 10.1642 12.4142 10.5 12 10.5H6C5.58579 10.5 5.25 10.1642 5.25 9.75Z"
                  fill="#6E747A"
                />
                <path
                  d="M6 12C5.58579 12 5.25 12.3358 5.25 12.75C5.25 13.1642 5.58579 13.5 6 13.5H12C12.4142 13.5 12.75 13.1642 12.75 12.75C12.75 12.3358 12.4142 12 12 12H6Z"
                  fill="#6E747A"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M1.5 3C1.5 1.75736 2.50736 0.75 3.75 0.75H10.5C10.6989 0.75 10.8897 0.829018 11.0303 0.96967L16.2803 6.21967C16.421 6.36032 16.5 6.55109 16.5 6.75V15C16.5 16.2426 15.4926 17.25 14.25 17.25H3.75C2.50736 17.25 1.5 16.2426 1.5 15V3ZM3.75 2.25C3.33579 2.25 3 2.58579 3 3V15C3 15.4142 3.33579 15.75 3.75 15.75H14.25C14.6642 15.75 15 15.4142 15 15V7.5H12C10.7574 7.5 9.75 6.49264 9.75 5.25V2.25H3.75ZM11.25 3.31066L13.9393 6H12C11.5858 6 11.25 5.66421 11.25 5.25V3.31066Z"
                  fill="#6E747A"
                />
              </svg>
              Status: <span class="font-[600] ml-1 text-capitalize">{{ post.status_code }}</span>
            </div>
          </b-dropdown>
        </b-btn-group>
        
        <b-dropdown
          variant="link"
          toggle-class="border !border-[#E2E5EC] h-[34px] bg-white ml-3"
          menu-class="w-[224px]"
          right
        >
          <template #button-content>
            <svg
              width="16"
              height="4"
              viewBox="0 0 16 4"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="8"
                cy="2"
                r="2"
                transform="rotate(-180 8 2)"
                fill="#6E747A"
              />
              <circle
                cx="14"
                cy="2"
                r="2"
                transform="rotate(-180 14 2)"
                fill="#6E747A"
              />
              <circle
                cx="2"
                cy="2"
                r="2"
                transform="rotate(-180 2 2)"
                fill="#6E747A"
              />
            </svg>
          </template>
          <b-link
            v-if="post.status_code === 'publish'"
            class="hover:no-underline flex items-center px-[16px] py-[12px] text-[14px] leading-[18px] text-[#6E747A] cursor-pointer hover:bg-[#FAFCFF] hover:text-[#4F83E3]"
            :href="`https://${project.domain || project.subdomain + '.estage.site'}/blog/${post.slug}`"
            target="_blank"
          >
            <icon
              icon="eye-3.svg"
              class="mr-2 w-4 h-4"
            />
            View Post
          </b-link>
          <div
            class="flex items-center px-[16px] py-[12px] text-[14px] leading-[18px] text-[#6E747A] cursor-pointer hover:bg-[#FAFCFF] hover:text-[#4F83E3]"
            @click="() => modal = true"
          >
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="mr-2 w-4 h-4"
            >
              <g clip-path="url(#clip0_8509_58813)">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M0.625 3.5C0.625 2.46447 1.46447 1.625 2.5 1.625H12.5C13.5355 1.625 14.375 2.46447 14.375 3.5V13.5C14.375 14.5355 13.5355 15.375 12.5 15.375H2.5C1.46447 15.375 0.625 14.5355 0.625 13.5V3.5ZM2.5 2.875C2.15482 2.875 1.875 3.15482 1.875 3.5V6H6.875V2.875H2.5ZM8.125 2.875V14.125H12.5C12.8452 14.125 13.125 13.8452 13.125 13.5V3.5C13.125 3.15482 12.8452 2.875 12.5 2.875H8.125ZM6.875 14.125V7.25H1.875V13.5C1.875 13.8452 2.15482 14.125 2.5 14.125H6.875Z"
                  fill="#6E747A"
                />
              </g>
              <defs>
                <clipPath id="clip0_8509_58813">
                  <rect
                    width="15"
                    height="15"
                    fill="white"
                    transform="translate(0 1)"
                  />
                </clipPath>
              </defs>
            </svg>
            Save As Layout Template
          </div>
          <div
            v-if="post.status_code === 'publish'"
            class="flex items-center px-[16px] py-[12px] text-[14px] leading-[18px] text-[#6E747A] cursor-pointer hover:bg-[#FAFCFF] hover:text-[#4F83E3]"
            @click="onUnpublish"
          >
            <svg
              width="17"
              height="17"
              viewBox="0 0 17 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="mr-2 w-4 h-4"
            >
              <path
                d="M3.83442 10.625L6.16688 8.29257C6.4435 8.01595 6.4435 7.56746 6.16688 7.29084C5.89026 7.01422 5.44177 7.01422 5.16515 7.29084L1.62348 10.8325C1.34686 11.1091 1.34686 11.5576 1.62348 11.8342L5.16515 15.3759C5.44177 15.6525 5.89026 15.6525 6.16688 15.3759C6.4435 15.0993 6.4435 14.6508 6.16688 14.3742L3.83442 12.0417H10.9785C13.5213 12.0417 15.5827 9.98035 15.5827 7.43754C15.5827 4.89473 13.5213 2.83337 10.9785 2.83337H6.37435C5.98315 2.83337 5.66602 3.15051 5.66602 3.54171C5.66602 3.93291 5.98315 4.25004 6.37435 4.25004H10.9785C12.7389 4.25004 14.166 5.67713 14.166 7.43754C14.166 9.19795 12.7389 10.625 10.9785 10.625H3.83442Z"
                fill="#6E747A"
              />
            </svg>
            Unpublish Post
          </div>
          <div
            v-if="post.status_code === 'publish'"
            class="flex items-center px-[16px] py-[12px] text-[14px] leading-[18px] text-[#6E747A] cursor-pointer hover:bg-[#FAFCFF] hover:text-[#4F83E3]"
            @click="() => modalDelete = true"
          >
            <svg
              width="17"
              height="17"
              viewBox="0 0 17 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="mr-2 w-4 h-4"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M7.08333 0.708374C6.69213 0.708374 6.375 1.02551 6.375 1.41671V2.83337H2.83333C2.44213 2.83337 2.125 3.15051 2.125 3.54171C2.125 3.93291 2.44213 4.25004 2.83333 4.25004H14.1667C14.5579 4.25004 14.875 3.93291 14.875 3.54171C14.875 3.15051 14.5579 2.83337 14.1667 2.83337H10.625V1.41671C10.625 1.02551 10.3079 0.708374 9.91667 0.708374H7.08333ZM9.20833 2.83337H7.79167V2.12504H9.20833V2.83337Z"
                fill="#6E747A"
              />
              <path
                d="M7.08333 7.79171C7.47454 7.79171 7.79167 8.10884 7.79167 8.50004V12.75C7.79167 13.1412 7.47454 13.4584 7.08333 13.4584C6.69213 13.4584 6.375 13.1412 6.375 12.75V8.50004C6.375 8.10884 6.69213 7.79171 7.08333 7.79171Z"
                fill="#6E747A"
              />
              <path
                d="M10.625 8.50004C10.625 8.10884 10.3079 7.79171 9.91667 7.79171C9.52547 7.79171 9.20833 8.10884 9.20833 8.50004V12.75C9.20833 13.1412 9.52547 13.4584 9.91667 13.4584C10.3079 13.4584 10.625 13.1412 10.625 12.75V8.50004Z"
                fill="#6E747A"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M2.83334 4.95837C2.628 4.95837 2.43276 5.04748 2.29822 5.20261C2.16369 5.35773 2.10308 5.5636 2.13212 5.76688L3.37503 14.4672C3.52458 15.5141 4.42117 16.2917 5.47867 16.2917H11.5213C12.5788 16.2917 13.4754 15.5141 13.625 14.4672L14.8679 5.76688C14.8969 5.5636 14.8363 5.35773 14.7018 5.20261C14.5672 5.04748 14.372 4.95837 14.1667 4.95837H2.83334ZM4.77746 14.2669L3.65005 6.37504H13.35L12.2226 14.2669C12.1727 14.6158 11.8738 14.875 11.5213 14.875H5.47867C5.12617 14.875 4.82731 14.6158 4.77746 14.2669Z"
                fill="#6E747A"
              />
            </svg>
            Delete Post
          </div>
        </b-dropdown>
      </template>

      <template #aside>
        <SidbearSettingsGroup
          title="Layout Templates"
          content-class="py-0"
        >
          <div class="overflow-auto  max-h-[470px] mr-[-21px] pr-[21px] pt-[14px] pb-[22px]">
            <div class="flex flex-col gap-[18px] w-100 align-items-center py-[18px]">
              <div
                v-for="(template, index) in templates"
                :key="template.id"
                class="shadow-sm h-[200px] overflow-hidden bg-center w-[200px] relative text-[14px] font-[700] flex justify-center items-center cursor-pointer transition bg-cover border-[1px] border-[#D7D7D7] rounded-[5px]"
                :class="{'border-[#4F83E3] border-[2px]': template.id === blogTemplate.id}"
                :style="{backgroundImage: `url(${template.screen})`}"
                @click="alertThenSelectTemplate(index)"
              >
                <span
                  v-if="template.id === 'blank'"
                  v-text="template.name"
                />
                <div
                  v-else
                  class="absolute bottom-0 text-white font-[700] text-[14px] left-0 h-[40px] bg-[rgba(0,0,0,.65)] flex items-center px-[12px] text-truncate w-[200px]"
                  v-text="template.name"
                />
              </div>
            </div>
          </div>
        </SidbearSettingsGroup>

        <SidbearSettingsGroup
          title="Blocks"
          content-class="p-0"
        >
          <blocks-drag-items :filtered-blocks="['empty-blocks']" />
        </SidbearSettingsGroup>

        <SidbearSettingsGroup
          title="Rows"
          :active="$store.state.editor.page === 'add-row'"
          content-class="p-0 no-scrollbar"
        >
          <rows-drag-items />
        </SidbearSettingsGroup>

        <SidbearSettingsGroup
          title="Elements"
          content-class="p-0"
          :active="$store.state.editor.page === 'add-element'"
        >
          <elements-drag-items editor="blog" />
        </SidbearSettingsGroup>

        <SidbearSettingsGroup
          title="Dynamic Content"
          content-class="p-0"
        >
          <dynamic-elements-drag-items :group="['Dynamic Post Template']" />
        </SidbearSettingsGroup>

        <SidbearSettingsGroup
          title="Favorites"
          content-class="p-0"
        >
          <favorites-drag-items class="border-top-0" />
        </SidbearSettingsGroup>

        <SidbearSettingsGroup
          ref="postSettingsTab"
          title="Post Settings"
          active
        >
          <div class="overflow-hidden">
            <post-settings
              ref="postSettings"
              v-model="post"
            />
          </div>
        </SidbearSettingsGroup>
      </template>

      <template v-if="!loadingRedoUndo">
        <content-drop-area
          v-if="blogTemplate.content"
          v-model="blogTemplate.content"
          :content-key="blogTemplate.id"
          drop-area-class="vh-70"
          group="blocks"
          add-group="blocks"
        />
      </template>
    </module-editor>
    <div
      v-else
      class="flex justify-center items-center vh-100"
    >
      <b-spinner variant="primary" />
    </div>

    <b-modal
      v-model="modalDelete"
      body-class="text-center py-5"
      size="md"
      hide-footer
      hide-header
      lazy
      centered
    >
      <h4 class="h3 !text-[24px] font-weight-bold my-4">
        Delete Blog Post
      </h4>

      <p class="mb-[32px]">
        The current content of the post will be deleted. <br> Are you sure?
      </p>

      <div class="d-flex align-items-center justify-content-center">
        <b-btn
          variant="danger"
          class="btn-wide mr-2"
          @click="onDelete"
        >
          Yes
        </b-btn>
        <b-btn
          class="btn-wide"
          @click="() => modalDelete = false"
        >
          No
        </b-btn>
      </div>
    </b-modal>
    
    <b-modal
      v-model="modalSchedule"
      body-class="publish-modal"
      hide-footer
      hide-header
      lazy
      size="md"
    >
      <b-link
        class="link-muted"
        @click="onCancelSchedule"
      >
        <svg
          fill="none"
          height="11"
          viewBox="0 0 11 11"
          width="11"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.71152 5.5L10.8243 1.38681C10.9374 1.27358 10.9998 1.12252 11 0.961445C11 0.80028 10.9376 0.649042 10.8243 0.535994L10.4639 0.175654C10.3506 0.0621585 10.1995 0 10.0383 0C9.87729 0 9.72624 0.0621585 9.61293 0.175654L5.50018 4.28858L1.38725 0.175654C1.27412 0.0621585 1.12298 0 0.961829 0C0.800854 0 0.649715 0.0621585 0.536585 0.175654L0.176 0.535994C-0.0586667 0.770676 -0.0586667 1.15239 0.176 1.38681L4.28884 5.5L0.176 9.61302C0.0627805 9.72642 0.000447154 9.87748 0.000447154 10.0386C0.000447154 10.1996 0.0627805 10.3507 0.176 10.464L0.536496 10.8243C0.649626 10.9378 0.800854 11 0.96174 11C1.12289 11 1.27403 10.9378 1.38716 10.8243L5.50009 6.71133L9.61284 10.8243C9.72615 10.9378 9.8772 11 10.0382 11H10.0383C10.1994 11 10.3505 10.9378 10.4638 10.8243L10.8242 10.464C10.9373 10.3508 10.9997 10.1996 10.9997 10.0386C10.9997 9.87748 10.9373 9.72642 10.8242 9.6131L6.71152 5.5Z"
            fill="#6E747A"
          />
        </svg>
      </b-link>
      <div class="pt-3 mb-4">
        <h4 class="text-center title mb-3">
          Schedule Your Post
        </h4>
        <h5 class="text-center subtitle">
          {{ post.title }}
        </h5>
      </div>

      <div class="flex flex-col mb-[32px] mx-auto w-[256px]">
        <date-picker
          v-model="fromDate"
          :clearable="false"
          :disabled-date="disabledBeforeToday"
          class="w-full"
          format="YYYY-MM-DD HH:mm"
          input-class="form-control"
          type="datetime"
          value-type="format"
        />
      </div>

      <div class="d-flex justify-content-center mb-3">
        <b-btn
          class="font-size-14 py-3 px-5 font-weight-bold rounded-right-0 w-[256px]"
          variant="primary"
          @click="onSchedule"
        >
          Schedule Post
        </b-btn>
      </div>
    </b-modal>
    
    <b-modal
      v-model="modal"
      hide-header
      hide-footer
      size="lg"
      centered
    >
      <b-link
        class="link-muted"
        @click="modal = false"
      >
        <svg
          fill="none"
          height="11"
          viewBox="0 0 11 11"
          width="11"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.71152 5.5L10.8243 1.38681C10.9374 1.27358 10.9998 1.12252 11 0.961445C11 0.80028 10.9376 0.649042 10.8243 0.535994L10.4639 0.175654C10.3506 0.0621585 10.1995 0 10.0383 0C9.87729 0 9.72624 0.0621585 9.61293 0.175654L5.50018 4.28858L1.38725 0.175654C1.27412 0.0621585 1.12298 0 0.961829 0C0.800854 0 0.649715 0.0621585 0.536585 0.175654L0.176 0.535994C-0.0586667 0.770676 -0.0586667 1.15239 0.176 1.38681L4.28884 5.5L0.176 9.61302C0.0627805 9.72642 0.000447154 9.87748 0.000447154 10.0386C0.000447154 10.1996 0.0627805 10.3507 0.176 10.464L0.536496 10.8243C0.649626 10.9378 0.800854 11 0.96174 11C1.12289 11 1.27403 10.9378 1.38716 10.8243L5.50009 6.71133L9.61284 10.8243C9.72615 10.9378 9.8772 11 10.0382 11H10.0383C10.1994 11 10.3505 10.9378 10.4638 10.8243L10.8242 10.464C10.9373 10.3508 10.9997 10.1996 10.9997 10.0386C10.9997 9.87748 10.9373 9.72642 10.8242 9.6131L6.71152 5.5Z"
            fill="#6E747A"
          />
        </svg>
      </b-link>
      <div class="py-[40px] px-[90px]">
        <h5 class="text-[27px] font-[600] text-center">
          Save Layout Template
        </h5>
        <p class="text-center mb-4">
          (Single Post Page)
        </p>

        <b-form @submit.prevent="saveAsTemplate">
          <b-form-group
            label="Give your Template a name:"
            label-class="!text-[15px] !text-[#5B5B5B] !font-[400]"
            class="!mb-[35px]"
          >
            <b-input
              v-model="layoutName"
              required
            />
          </b-form-group>

          <modal-button
            :loading="modalLoading"
          >
            Save Layout Template
          </modal-button>
        </b-form>
      </div>
    </b-modal>
  </div>
</template>

<script>
import moment from 'moment'
import {mapState} from 'vuex'
import {applyPageStyles, pageStylesToString} from '@/utils/useStylesheet'
import DatePicker from 'vue2-datepicker'
import ResolutionMixin from '../../components/mixins/ResolutionMixin'
import ViewportUpdate from '@/mixins/viewportUpdate'
import ContentDropArea from '@/components/editor/components/ContentDropArea.vue'
import ModuleEditor from '@/views/module-editor/ModuleEditor.vue'
import DynamicElementsDragItems from '@/components/editor/components/DynamicElementsDragItems.vue'
import BlocksDragItems from '@/components/editor/components/BlocksDragItems.vue'
import PostSettings from '@/views/blog/PostSettings.vue'
import ElementsDragItems from '@/components/editor/components/ElementsDragItems.vue'
import FavoritesDragItems from '@/components/editor/components/FavoritesDragItems.vue'
import SidbearSettingsGroup from '@/components/editor/components/SidbearSettingsGroup.vue'
import RowsDragItems from '@/components/editor/components/RowsDragItems.vue'
import ModalButton from '@/components/editor/components/ModalButton.vue'
import Screenshot from '@/mixins/Screenshot'
import UndoRedoSupport from '@/components/mixins/UndoRedoSupport'
import defaultTempaltes from './post-template/post-content-templates'
import Icon from '@/components/editor/utils/Icon.vue'
export default {
  name: 'PostEditor',
  components: {
    Icon,
    ModalButton,
    RowsDragItems,
    SidbearSettingsGroup,
    FavoritesDragItems,
    ElementsDragItems,
    PostSettings,
    BlocksDragItems,
    DynamicElementsDragItems,
    ModuleEditor,
    ContentDropArea,
    DatePicker
  },
  mixins: [ResolutionMixin, ViewportUpdate, Screenshot, UndoRedoSupport],
  provide () {
    return {
      getCustomPostEditorData: ()=> this.post,
      updateCustomPostEditorData: (key,val)=> {
        this.$set(this.post,key,val)
      }
    }
  },
  data () {
    return {
      subjectStyle: 'subject-styles',
      modal: false,
      modalSchedule: false,
      modalDelete: false,
      showWarnModal: true,
      layoutName: '',
      modalLoading: false,
      selectedTemplate: 0,
      loading: false,
      interval: null,
      settings: {
        hideHeader: false,
        hideFooter: false,
        sidebar: {
          enable: true,
          right: true
        }
      },
      templates: defaultTempaltes,
      favoriteBlock: {},
      blogTemplate: {},
      post: {
        allow_comment_guests: true,
        preview: {},
        'cover': '',
        'cover_thumb': '',
        'title': '',
        'description': '',
        'content': '',
        'code_injection': '',
        'status_code': 'draft',
        'fonts': '',
        'google_fonts': '',
        'custom_fonts': '',
        'categories': [],
        'author_id': null,
        'show_author': true,
        'show_date': true,
        'show_categories': true,
        'show_likes': true,
        'allow_comments': true,
        'allow_sharing': true,
        'seo_title': '',
        'post_date': '',
        'seo_keywords': '',
        'seo_description': '',
        'template_type': '',
        'template_id': 0,
        'seo_index': true,
        'comments_moderation': false
      },
      blogPublic: {}
    }
  },

  computed: {
    ...mapState('projects', {
      project: state => state.project
    }),
    getTargetContent: {
      get () {
        return this.blogTemplate.content
      },
      set (val) {
        this.$set(this.blogTemplate, 'content', val)
      }
    },
    fromDate: {
      get () {
        // return toLocalTime(this.post.post_date)
        return this.post.post_date
      },
      set (val) {
        this.post.post_date = val
      }
    }
  },

  async beforeRouteLeave (to, from, next) {
    const answer = await this.$refs.editor.confirmLeave()
    if (!answer) return false
    next()
  },

  async created () {
    await this.fetchTemplates()

    this.$store.commit('editor/SET_PAGE', '')

    if (!this.post) {
      await this.$store.dispatch('blogEditor/startEdit', {
        id: this.$route.params.post_id
      })
    }

    this.$store.commit('historyChanges/SET_DISABLE', false)

    this.$store.state.editor.backLink = {
      name: 'settings.blog'
    }

    if (this.$route.params.post_id !== 'new') {
      axios.get(`https://blog-api.estage.com/api/projects/${this.project.id}/blog/manage/posts/${this.$route.params.post_id}`)
        .then(({data}) => {
          this.post = {
            ...data.data,
            categories: data.data.categories.map(cat => cat.id),
            preview: {
              src: data.data.cover
            }
          }

          let css = null
          fetch(data.data.css_file_draft)
            .then(cssData => {
              css = [...cssData.matchAll(/\.([a-zA-Z0-9_-]+)\s*\{/g)].map(match => match[1])
              if (css === null) css = data.data.css
              this.blogTemplate = {
                ...data.data,
                content: data.data.content_draft !== null ? JSON.parse(data.data.content_draft) : JSON.parse(data.data.content)
              }
              this.blogPublic.content = JSON.parse(data.data.content)
              applyPageStyles(css, this.subjectStyle)
              this.initUndoRedoSupport()
              this.$store.commit('blogEditor/EDIT_POST', this.post)
              this.$store.commit('blogEditor/EDIT_BLOG_TEMPLATE', this.blogTemplate)
            })
            .catch(err => {
              console.error(err)
              this.blogTemplate = {
                ...data.data,
                content: data.data.content_draft !== null ? JSON.parse(data.data.content_draft) : JSON.parse(data.data.content)
              }
              applyPageStyles(data.data.css, this.subjectStyle)
              this.initUndoRedoSupport()
              this.$store.commit('blogEditor/EDIT_POST', this.post)
              this.$store.commit('blogEditor/EDIT_BLOG_TEMPLATE', this.blogTemplate)
            })
        })
    } else {
      const main = this.templates.findIndex(t=> t.is_main)
      await this.selectTemplate(main !== -1 ? main : 1)
      this.initUndoRedoSupport()
    }
  },

  methods: {
    getTargetCSS () {
      return pageStylesToString(this.subjectStyle)
    },
    onSave (options = {}) {
      this.$store.commit('editor/SET_PAGE', 'page-settings')

      setTimeout(() => {
        if (!this.$refs.postSettingsTab.open) this.$refs.postSettingsTab.toggle()

        setTimeout(() => {
          this.$refs.postSettings.validate()
            .then(() => {
              this.loading = true
              this.$store.dispatch('font/prepareFonts', {}, {root: true})
                .then((fonts) => {
                  if (this.$route.params.post_id === 'new') {
                    axios.post(`https://blog-api.estage.com/api/projects/${this.project.id}/blog/manage/posts`, {
                      ...this.post,
                      ...options,
                      fromDate: this.post.fromDate,
                      post_date: this.post.post_date.length !== 0 ? this.post.post_date : null,
                      content: JSON.stringify(this.blogTemplate.content),
                      content_draft: JSON.stringify(this.blogTemplate.content),
                      css: pageStylesToString(this.subjectStyle),
                      css_draft: pageStylesToString(this.subjectStyle),
                      fonts: fonts.googleFonts + fonts.customFonts
                    })
                      .then(({data}) => {
                        this.$swal({
                          icon: 'success',
                          title: 'Post successfully created!',
                          showConfirmButton: false,
                          timer: 1500
                        })

                        this.post.slug = data.data.slug
                        this.post.status_code = data.data.status_code

                        this.$router.push({name: '', params: {post_id: data.data.id}})
                      })
                      .finally(() => {
                        this.loading = false
                      })
                  } else {
                    axios.put(`https://blog-api.estage.com/api/projects/${this.project.id}/blog/manage/posts/${this.$route.params.post_id}`, {
                      ...this.post,
                      ...options,
                      fromDate: this.post.fromDate,
                      post_date: this.post.post_date,
                      content: JSON.stringify(this.blogTemplate.content),
                      content_draft: JSON.stringify(this.blogTemplate.content),
                      css: pageStylesToString(this.subjectStyle),
                      css_draft: pageStylesToString(this.subjectStyle),
                      fonts: fonts.googleFonts + fonts.customFonts
                    })
                      .then(({data}) => {
                        this.$swal({
                          icon: 'success',
                          title: 'Post successfully updated!',
                          showConfirmButton: false,
                          timer: 1500
                        })

                        this.post.status_code = data.data.status_code
                      })
                      .finally(() => {
                        this.loading = false
                      })
                  }
                })
            })
            .catch(() => {
              document.querySelector('.scroll-wrapper .text-danger').closest('.form-group').scrollIntoView({behavior: 'smooth'})
            })
        })
      })
    },
    onPublish (options) {
      if (this.$route.params.post_id === 'new') {
        this.onSave({status_code: 'publish'})
      } else {
        this.$store.commit('editor/SET_PAGE', 'page-settings')

        setTimeout(() => {
          if (!this.$refs.postSettingsTab.open) this.$refs.postSettingsTab.toggle()

          setTimeout(() => {
            this.$refs.postSettings.validate()
              .then(() => {
                this.loading = true
                this.$store.dispatch('font/prepareFonts', {}, {root: true})
                  .then((fonts) => {
                    const data = this.post
                    delete data.fromDate, delete data.post_date, delete data.status_code
                    axios.put(`https://blog-api.estage.com/api/projects/${this.project.id}/blog/manage/posts/${this.$route.params.post_id}`, {
                      ...options,
                      ...data,
                      content: JSON.stringify(this.blogTemplate.content),
                      content_draft: JSON.stringify(this.blogTemplate.content),
                      css: pageStylesToString(this.subjectStyle),
                      css_draft: pageStylesToString(this.subjectStyle),
                      fonts: fonts.googleFonts + fonts.customFonts
                    })
                      .then(({data: post}) => {
                        axios.put(`https://blog-api.estage.com/api/projects/${this.project.id}/blog/manage/posts/${this.$route.params.post_id}/status`, {
                          upd_content: true,
                          css_draft: pageStylesToString(this.subjectStyle),
                          status_code: 'publish'
                        })
                          .then(({data: status}) => {
                            this.$swal({
                              icon: 'success',
                              title: 'Post successfully Published!',
                              showConfirmButton: false,
                              timer: 1500
                            })

                            if (this.$route.params.post_id === 'new') this.$router.push({name: '', params: {post_id: post.data.id}})
                            this.post.status_code = status.data.status_code
                          })
                      })
                      .finally(() => {
                        this.loading = false
                      })
                  })
              })
          })
        })
      }
    },
    onUnpublish () {
      this.$store.commit('editor/SET_PAGE', 'page-settings')

      setTimeout(() => {
        if (!this.$refs.postSettingsTab.open) this.$refs.postSettingsTab.toggle()

        setTimeout(() => {
          this.$refs.postSettings.validate()
            .then(() => {
              this.loading = true
              this.$store.dispatch('font/prepareFonts', {}, {root: true})
                .then((fonts) => {
                  const data = this.post
                  delete data.fromDate, delete data.post_date, delete data.status_code
                  axios.put(`https://blog-api.estage.com/api/projects/${this.project.id}/blog/manage/posts/${this.$route.params.post_id}`, {
                    ...data,
                    content: JSON.stringify(this.blogTemplate.content),
                    content_draft: JSON.stringify(this.blogTemplate.content),
                    css: pageStylesToString(this.subjectStyle),
                    css_draft: pageStylesToString(this.subjectStyle),
                    fonts: fonts.googleFonts + fonts.customFonts
                  })
                    .then(({data: post}) => {
                      axios.put(`https://blog-api.estage.com/api/projects/${this.project.id}/blog/manage/posts/${this.$route.params.post_id}/status`, {
                        upd_content: false,
                        status_code: 'draft'
                      })
                        .then(({data: status}) => {
                          this.$swal({
                            icon: 'success',
                            title: 'Post successfully Unpublished',
                            showConfirmButton: false,
                            timer: 1500
                          })

                          if (this.$route.params.post_id === 'new') this.$router.push({name: '', params: {post_id: post.data.id}})
                          this.post.status_code = status.data.status_code
                        })
                    })
                    .finally(() => {
                      this.loading = false
                    })
                })
            })
        })
      })
    },
    onDraft () {
      if (this.$route.params.post_id === 'new') {
        this.$store.commit('editor/SET_PAGE', 'page-settings')

        setTimeout(() => {
          if (!this.$refs.postSettingsTab.open) this.$refs.postSettingsTab.toggle()

          setTimeout(() => {
            this.$refs.postSettings.validate()
              .then(() => {
                this.loading = true
                this.$store.dispatch('font/prepareFonts', {}, {root: true})
                  .then((fonts) => {
                    axios.post(`https://blog-api.estage.com/api/projects/${this.project.id}/blog/manage/posts`, {
                      ...this.post,
                      fromDate: this.post.fromDate,
                      post_date: this.post.post_date.length !== 0 ? this.post.post_date : null,
                      content: JSON.stringify(this.blogTemplate.content),
                      content_draft: JSON.stringify(this.blogTemplate.content),
                      css: pageStylesToString(this.subjectStyle),
                      css_draft: pageStylesToString(this.subjectStyle),
                      fonts: fonts.googleFonts + fonts.customFonts,
                      status_code: 'draft'
                    })
                      .then(({data: post}) => {
                        axios.put(`https://blog-api.estage.com/api/projects/${this.project.id}/blog/manage/posts/${post.data.id}/status`, {
                          upd_content: false,
                          status_code: 'draft'
                        })
                          .then(({data: status}) => {
                            this.$swal({
                              icon: 'success',
                              title: 'Post successfully created!',
                              showConfirmButton: false,
                              timer: 1500
                            })

                            if (this.$route.params.post_id === 'new') this.$router.push({name: '', params: {post_id: post.data.id}})
                            this.post.status_code = status.data.status_code
                          })
                      })
                      .finally(() => {
                        this.loading = false
                      })
                  })
              })
          })
        })
      } else {
        this.$store.commit('editor/SET_PAGE', 'page-settings')

        setTimeout(() => {
          if (!this.$refs.postSettingsTab.open) this.$refs.postSettingsTab.toggle()

          setTimeout(() => {
            this.$refs.postSettings.validate()
              .then(() => {
                this.loading = true
                this.$store.dispatch('font/prepareFonts', {}, {root: true})
                  .then((fonts) => {
                    const data = this.post
                    delete data.fromDate, delete data.post_date, delete data.status_code
                    axios.put(`https://blog-api.estage.com/api/projects/${this.project.id}/blog/manage/posts/${this.$route.params.post_id}`, {
                      ...data,
                      content: JSON.stringify(this.blogPublic.content),
                      content_draft: JSON.stringify(this.blogTemplate.content),
                      css: pageStylesToString(this.subjectStyle),
                      css_draft: pageStylesToString(this.subjectStyle),
                      fonts: fonts.googleFonts + fonts.customFonts
                    })
                      .then(({post}) => {
                        this.$swal({
                          icon: 'success',
                          title: 'Post successfully saved',
                          showConfirmButton: false,
                          timer: 1500
                        })

                        if (this.$route.params.post_id === 'new') this.$router.push({name: '', params: {post_id: post.data.id}})
                        this.post.status_code = post.data.status_code
                      })
                      .finally(() => {
                        this.loading = false
                      })
                  })
              })
          })
        })
      }
    },
    onSchedule () {
      this.modalSchedule = false
      this.onSave({status_code: 'publish'})
    },
    onCancelSchedule () {
      this.modalSchedule = false
      this.post.post_date = moment().format('YYYY-MM-DD HH:mm')
      this.onPublish({post_date: this.post.post_date})
    },
    onScheduleSave () {
      this.modalSchedule = false
      this.onSave({status_code: 'draft'})
    },
    onDelete () {
      this.showWarnModal = false
      setTimeout(() => {
        axios.delete(`https://blog-api.estage.com/api/projects/${this.project.id}/blog/manage/posts/${this.$route.params.post_id}`)
        this.$router.push({name: 'settings.blog', query: {page: 'posts'}})
      }, 300)
    },
    saveAsTemplate () {
      this.modalLoading = true

      this.takeScreenshot(document.getElementById('editor-container').querySelector('div > div'))
        .then((screen) => {
          axios.post(`https://blog-api.estage.com/api/projects/${this.project.id}/blog/manage/posts/templates?template_type=single`, {
            template_type: 'single',
            content: JSON.stringify(this.blogTemplate.content),
            css: pageStylesToString(this.subjectStyle),
            name: this.layoutName,
            has_sidebar: this.settings.sidebar.enable,
            sidebar_right: this.settings.sidebar.right,
            hide_header: this.settings.hideHeader || false,
            hide_footer: this.settings.hideFooter || false,
            is_main: false,
            screen
          })
            .then(() => {
              this.$swal({
                icon: 'success',
                iconColor: '#4F83E3',
                toast: true,
                position: 'top-right',
                title: 'Saved!',
                showConfirmButton: false,
                timer: 1500
              })

              this.fetchTemplates()
                .then(() => {
                  // this.selectTemplate(2)
                })
            })
            .catch(() => {
              this.$swal({
                icon: 'error',
                iconColor: '#4F83E3',
                toast: true,
                position: 'top-right',
                title: 'Server Error.',
                showConfirmButton: false,
                timer: 1500
              })
            })
            .finally(() => {
              this.modalLoading = false
              this.modal = false
            })
        })
    },
    async fetchTemplates () {
      this.loading = true
      return await axios.get(`https://blog-api.estage.com/api/projects/${this.project.id}/blog/manage/posts/templates?template_type=single`)
        .then(({data}) => {
          this.templates = [...this.templates, ...data.data.data.filter(temp => temp.screen)]
        })
        .finally(() => {
          this.loading = false
        })
    },
    alertThenSelectTemplate (index){
      this.$swal({
        icon: 'warning',
        html: 'You are about to switch to another template. <br> Your changes will be lost. <br> Are you sure you want to proceed?',
        showConfirmButton: true,
        showCancelButton: true,
        focusConfirm: false,
        focusCancel:true,
        confirmButtonText: 'Yes, switch to the template'
      }).then((result) => {
        if(result.isConfirmed) this.selectTemplate(index)
      })
    },
    selectTemplate (index) {
      this.$store.commit('editor/SET_PAGE', '')

      if (!this.templates[index].css) {
        this.loading = true
        return axios.get(`https://blog-api.estage.com/api/projects/${this.project.id}/blog/manage/posts/templates/${this.templates[index].id}`)
          .then(({data}) => {
            this.templates[index].content = JSON.parse(data.data.content)
            this.templates[index].css = data.data.css
            this.blogTemplate = {
              ...data.data,
              content: JSON.parse(data.data.content)
            }
            applyPageStyles(data.data.css, this.subjectStyle)
            this.initUndoRedoSupport()
          })
          .finally(() => {
            this.loading = false
          })
      }

      this.blogTemplate = JSON.parse(JSON.stringify(this.templates[index]))
      applyPageStyles(this.blogTemplate.css, this.subjectStyle)
    },
    disabledBeforeToday (date) {
      const today = moment().subtract(1, 'days').format('YYYY-MM-DD HH:mm')
      return moment(date).isBefore(today)
    }
  },
  mounted () {
    if (this.post.post_date === null || this.post.post_date === '') {
      this.post.post_date = moment().format('YYYY-MM-DD HH:mm')
    }
  }
}
</script>

<style scoped>
.social-icon {
  width: 35.44px;
  height: 33px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
}
</style>
